import React, {FunctionComponent} from 'react';
import {WhiteBlock} from '../../components/Blocks';
import DogsImg from '../../assets/img/images/BackofBoxtrans.png';
import {BottomWhiteBlock, Container, DogsImage, RedText} from './FinalPage.styles';
import {Header} from '../../components/DNADoggoSays/DNADoggoSays.styles';
import {PageProps} from '../../dto/PageType';
import Blob from '../../components/Blob/Blob';
import {useFadeInRight, useScale} from '../../hooks/animation';

function FinalPage({userInfo, ...rest}: PageProps) {
  const effect1 = useScale();
  const effect2 = useFadeInRight();
  return (
    <Container {...rest}>
      <div style={{zIndex: 1}}>
        <WhiteBlock style={{padding: '1rem', zIndex: 1, ...effect2}}>
          <RedText>
            “Dogs speak, but only to those who know how to listen”
            <br />- Orhan Pamuk
          </RedText>
          Feedback, questions or just want to get in touch?
          <br />
          Throw us a bone at{' '}
          <a
            href={'mailto:hello@treattherapeutics.com'}
            target={'_blank'}
            rel="noreferrer"
          >
            <b>hello@treattherapeutics.com</b>
          </a>
        </WhiteBlock>
        <DogsImage className={'hide-on-mobile'} src={DogsImg} style={effect1} />
        <Blob variant={3} />
        <Header className={'disclaimer'}>DISCLAIMER!</Header>
        <BottomWhiteBlock>
          Whilst we believe in feeding real, nutritious whole foods & getting
          outdoors and exercising, every dog is different. Remember to introduce
          changes gradually into {userInfo.dog_name}&#39;s lifestyle. Treat
          Therapeutics Pte. Ltd. do not take any legal liability in the event of
          an adverse event as a result of owner decisions.
        </BottomWhiteBlock>
      </div>
    </Container>
  );
}

export default FinalPage as FunctionComponent<PageProps>;
