function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0');
}

export function formatDate(dateSrt?: string): string {
	if (!dateSrt) return ' - ';

	const date = new Date(dateSrt);
	return (
		[
			padTo2Digits(date.getMonth() + 1),
			padTo2Digits(date.getDate()),
			date.getFullYear(),
		].join('/')
	);
}
