import React, {FunctionComponent} from 'react';
import {Container, WBlockWithHeader} from './AlphaDiversityGraphPage.styles';
import {WhiteBlock} from '../../../components/Blocks';
import DNADoggoSays from '../../../components/DNADoggoSays/DNADoggoSays';
import {COLORS} from '../../../const/colors';
import {PageProps} from '../../../dto/PageType';
import MicrobionIcon from '../../../assets/img/icons/Microbiome Analysis — white.svg';
import LineScore from '../../../components/LineScore';

function AlphaDiversityGraphPage({userInfo, ...rest}: PageProps) {
  return (
    <Container {...rest}>
      <DNADoggoSays
        hideDoggoOnMobile
        maxHeaderHeight={'300px'}
        maxHeaderWidth={'200px'}
        additionalBlocks={
          <WhiteBlock style={{marginTop: '1.5rem', marginBottom: '1.5rem'}}>
            Did you know for humans, it is recommended to incorporate 30 different natural plant-based foods into our diets each week to help keep the gut microbiome healthy?
          </WhiteBlock>
        }
      >
        <div>
        Alpha diversity is a metric that rates how much variety there is within the community of bacteria in {userInfo.dog_name}'s sample, the more variety the better!
        </div>
      </DNADoggoSays>
      <WBlockWithHeader
        header={'ALPHA DIVERSITY'}
        headerColor={COLORS.brown}
        icon={MicrobionIcon}
      >
        {userInfo.alpha_diversity2 ? (
          <>
            <LineScore value={userInfo.alpha_diversity2} max={10} />
            <div className={'info-block'}>
              {userInfo.alpha_diversity2 < 4 && (
                <>
                  <div>
                    <b>
                      {userInfo.dog_name}&#39;s overall alpha diversity score is
                      a little bit on the{' '}
                      <span className={'red-text'}>
                        <b>lower</b>
                      </span>{' '}
                      side.
                    </b>
                  </div>
                  <div>
                    Research has associated low diversity with diseases such as IBD in dogs. Research in dogs is in its early days, but so far it is clear our four-legged friends and their gut-bugs need variety in their diet - just like we do!
                  </div>
                  <div>
                    Fear not, it is very easy to increase {userInfo.dog_name}'s alpha diversity score and we can tell you exactly how by taking a closer look at the evenness and richness of the species of bacteria in {userInfo.dog_name}'s gut - see next page!
                  </div>
                </>
              )}
              {userInfo.alpha_diversity2 <= 6 && userInfo.alpha_diversity2 >= 4 && (
                <>
                  <div>
                    {userInfo.dog_name}&#39;s overall alpha diversity score is
                    {' '}
                    <span style={{color: '#ecb33d'}}>
                      <b>average</b>
                    </span>
                    .
                  </div>
                  <div>
                    Research has associated low diversity with diseases such as IBD in dogs, so it's good to keep this score as high as possible! Research in dogs is in its early days, but so far it is clear our four-legged friends and their gut-bugs need variety in their diet - just like we do!
                  </div>
                  <div>
                    Alpha diversity is an overview, let's take a closer look at the evenness and richness of the species of bacteria in {userInfo.dog_name}'s gut - see next page!
                  </div>
                </>
              )}
              {userInfo.alpha_diversity2 > 6 && (
                <>
                  <div>
                    {userInfo.dog_name}&#39;s overall alpha diversity score is
                    {' '}
                    <span className={'green-text'}>
                      <b>looking good</b>
                    </span>
                    !
                  </div>
                  <div>
                    Research has associated low diversity with diseases such as IBD in dogs, so it's good to keep this score as high as possible! Research in dogs is in its early days, but so far it is clear our four-legged friends and their gut-bugs need variety in their diet - just like we do!
                  </div>
                  <div>
                  Alpha diversity is an overview, let’s take a closer look at the evenness and richness of the species of bacteria in {userInfo.dog_name}'s gut - see next page!
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div>No data provided for this study!</div>
        )}
      </WBlockWithHeader>
    </Container>
  );
}

export default React.memo(AlphaDiversityGraphPage) as FunctionComponent<PageProps>;
