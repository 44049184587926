import React, {FunctionComponent} from 'react';
import {Container} from './GenusPage.styles';
import {COLORS} from '../../../const/colors';
import MicroIcon from '../../../assets/img/icons/height-icon.png';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import {PageProps} from '../../../dto/PageType';

function GenusPage({userInfo, ...rest}: PageProps) {
  return (
    <Container {...rest}>
      <div>
        <BlockWithHeader
          header={'GENUS BREAKDOWN'}
          headerColor={COLORS.green}
          blockColor={'white'}
          icon={MicroIcon}
        >
          <div>
           If we dig deep into each phylum, we can see even smaller, more specific groups of bacteria - each of
            these groups is a genus (plural “genera”).
            A healthy microbiome will be made up of lots of genera.
            Each genus helps carry out a specific function in your dog’s gut.
          </div><br />
          <div>
           If there is not enough groups of bacteria (genera), this disrupts regular functioning of your dog’s gut!
            If the microbiome is imbalanced, it is easy to spot as a group of bacteria will be taking up either
            too little or too much space in the gut - we can assess this by comparing the groups of bacteria
            in {userInfo.dog_name}'s gut versus the genera typically found in healthy dogs.
          </div><br />
          <div
            className='phyla_images'
            style={{display: 'flex', flexWrap: 'wrap'}}
          >
            {userInfo.genus_chart_img || userInfo.genus_avg_link ? (
              <>
                {userInfo.genus_avg_link && (
                  <img className={'imageChart'} src={userInfo.genus_avg_link} />
                )}
                {userInfo.genus_chart_img && (
                  <img
                    className={'imageChart'}
                    src={userInfo.genus_chart_img}
                  />
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </BlockWithHeader>
      </div>
    </Container>
  );
}

export default React.memo(GenusPage) as FunctionComponent<PageProps>;
