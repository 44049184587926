import React, {FunctionComponent, useState} from 'react';
import {Container, Image} from '../../ScoresGraphs/ScoreGraphs.styles';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import {PageProps} from '../../../dto/PageType';
import BaseDietIcon from '../../../assets/img/icons/Base Diet.svg';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import {COLORS} from '../../../const/colors';
import ItchScratchImage from '../../../assets/img/images/Itch + Scratch.webp';
import FluffyFullImage from '../../../assets/img/images/FluffyFull.webp';
import Blob from '../../../components/Blob/Blob';
import {BottomBlock, FoodForPet} from '../../../components/Blocks';

function FatPage({userInfo, ...rest}: PageProps) {
  const values = [
    {value: 0, label: 'Inefficient'},
    {value: 5, label: 'Ok'},
    {value: 10, label: 'Efficient'},
  ];
  const [idImageToShow, setIsImageToShow] = useState<string>();

  return (
    <Container {...rest}>
      {userInfo.dog_image && (
        <Image>
          <img src={userInfo.dog_image}/>
        </Image>
      )}
      <div>
        <BlockWithHeader
          header={'FAT SCORE'}
          icon={BaseDietIcon}
          headerColor={COLORS.darkBrown}
        >
          {userInfo.fat_score ? (
            <>
              <LinearGraph
                value={userInfo.fat_score}
                dogName={userInfo.dog_name}
                values={values}
              />
              {userInfo.fat_score > 6 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut is{' '}
                    <span style={{color: '#95b660'}}>efficient</span> at
                    breaking down fat!
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s gut has{' '}
                    <span style={{color: '#95b660'}}>a lot of bacteria</span>{' '}
                    that help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} break down and digest fat.
                    This means that they absorb a lot of fat from their diet with
                    little effort!
                    It is important to make sure {userInfo.dog_name} is not absorbing{' '}
                    <span style={{color: '#95b660'}}>too much</span> fat.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                  Sufficient fat is super important in preventing itchy flaky skin,
                  hair loss, and weight loss.
                  <span style={{color: '#95b660'}}>BUT,</span> high fat levels can
                  cause serious health concerns such as obesity and pancreatitis.
                  Please discuss further in your consultation,
                  lower fat meats might be more suitable for {userInfo.dog_name}!
                  </div>
                </>
              )}
              {userInfo.fat_score <= 6 && userInfo.fat_score >= 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut is{' '}
                    <span style={{color: '#ecb33d'}}>ok</span> at breaking down
                    fat.
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s sample contains{' '}
                    <span style={{color: '#ecb33d'}}>some bacteria</span> that help break
                    down and digest fat.
                    This means that although {userInfo.dog_gender === 'FEMALE' ? 'she' : 'he'} is probably absorbing sufficient fat
                    from {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet, {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} gut has to work a little harder than others to do so.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    As {userInfo.dog_name} is ok at breaking down fat, watch out for
                    signs of low fat levels: itchy flaky skin, hair loss, and weight loss.
                    Including higher fat meats can help prevent this.
                    Alternatively, consider using supplements that contain digestive
                    enzymes to help {userInfo.dog_name} break down fats more efficiently.
                  </div>
                </>
              )}
              {userInfo.fat_score < 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut is{' '}
                    <span style={{color: '#ed3560'}}>inefficient </span> at
                    breaking down fat.
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s sample contains{' '}
                    <span style={{color: '#ed3560'}}>very few</span> bacteria that help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'}
                    break down and digest fat.
                    {userInfo.dog_gender === 'FEMALE' ? 'Her' : 'His'} gut has to do a lot of work to break down a little fat - {userInfo.dog_name}
                    &nbsp;may not be absorbing enough fat for optimal health.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Low fat levels can cause itchy flaky skin, hair loss,
                    and weight loss.
                    Include higher fat meats and/or foods that are high in healthy fats
                    in {userInfo.dog_name}'s diet to prevent this.
                    Alternatively, consider using supplements that contain digestive
                    enzymes to help break down the fat that {userInfo.dog_name} already
                    gets from their diet!
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <LinearGraph
                value={0}
                dogName={userInfo.dog_name}
                values={values}
              />
              <>
                <div style={{margin: '2rem 0'}}>
                  {userInfo.dog_name}’s gut is{' '}
                  <span style={{color: '#ed3560'}}>inefficient </span> at
                  breaking down fat.
                </div>
                <div className="subtitle">What does this mean?</div>
                <div style={{marginBottom: '2rem'}}>
                  {userInfo.dog_name}’s gut has very few bacteria that
                  help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} break down and digest fat.
                  This means that {userInfo.dog_gender === 'FEMALE' ? 'she' : 'he'} gets fat
                  from {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet with significant
                  effort. {userInfo.dog_name} may not be absorbing enough fat
                  for {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} health.
                </div>
                <div className="subtitle">Why should I care?</div>
                <div>
                  Low fat levels cause itchy flaky skin, and hair loss.
                  Include higher fat meats in {userInfo.dog_name}’s diet to
                  prevent this.{' '}
                </div>
              </>
            </>
          )}
          <BottomBlock>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {userInfo?.fat_food_recommendation?.length ? (
                <>
                  <div className="subtitle">What to feed:</div>
                  {userInfo?.fat_food_recommendation.map((elem) => (
                    <div
                      key={elem.id}
                      style={{position: 'relative'}}
                      onMouseOver={(e) => (
                        e.stopPropagation(), setIsImageToShow(elem.id)
                      )}
                      onMouseLeave={() => setIsImageToShow('')}
                    >
                      <a href={elem.link_url} target="_blank" rel="noreferrer">
                        <img
                          src={elem.image_url}
                          className={`food-image ${
                            idImageToShow === elem.id && 'hover'
                          }`}
                        />
                        {idImageToShow === elem.id && (
                          <div className="text-for-icon">{elem.name}</div>
                        )}
                      </a>
                    </div>
                  ))}
                </>
              ) : (
                ''
              )}
            </div>
            {userInfo?.show_supplements && <>
              {userInfo.fat_score ? (
                <>
                  {userInfo.fat_score <= 4 && (
                    <>
                      <FoodForPet
                        color="#81bacd"
                        btnColor="#1c3a53"
                        backGroundColor="#2a5374"
                      >
                        <div className="text">
                          Dry itchy skin is a clear symptom of a fat deficiency.
                          <br/> <br/>
                          <span>
                          ITCH+SCRATCH gives {userInfo.dog_name} the fat
                          supplementation they need
                        </span>
                          <a
                            href="https://www.treattherapeutics.com/products/itch-scratch?utm_source=report&utm_medium=button&utm_campaign=report-rec-itch&utm_id=report-rec-itch"
                            target="_blank"
                            rel="noreferrer"
                            className="learnmore-btn"
                          >
                            Learn more
                          </a>
                        </div>
                        <img src={ItchScratchImage}/>
                      </FoodForPet>
                    </>
                  )}
                  {userInfo.fat_score <= 6 && userInfo.fat_score >= 4 && (
                    <>
                      <FoodForPet
                        color="#e5abac"
                        btnColor="#832f56"
                        backGroundColor="#a23f6d"
                      >
                        <div className="text">
                          Poor coat condition is a symptom of fat deficiency.
                          <br/> <br/>
                          <span>
                          FLUFFY+FULL can give {userInfo.dog_name} the support
                          they need
                        </span>
                          <a
                            href="https://www.treattherapeutics.com/products/fluffy-full?utm_source=report&utm_medium=button&utm_campaign=report-rec-fluffy&utm_id=report-rec-fluffy"
                            target="_blank"
                            rel="noreferrer"
                            className="learnmore-btn"
                          >
                            Learn more
                          </a>
                        </div>
                        <img src={FluffyFullImage}/>
                      </FoodForPet>
                    </>
                  )}
                </>
              ) : (
                ''
              )}
            </>}
          </BottomBlock>
        </BlockWithHeader>
      </div>
      <Blob variant={3}/>
    </Container>
  );
}

export default React.memo(FatPage) as FunctionComponent<PageProps>;
