import React, {FunctionComponent} from 'react';
import {Block, Container, Header} from './DNADoggoSays.styles';
import {DNADoggoSaysProps} from './index';
import doctorDogImg from '../../assets/img/images/doctor-dog.png';
import {BlueBlock} from '../Blocks';
import {animated} from 'react-spring';
import {useFadeInRight} from '../../hooks/animation';

function DNADoggoSays({
  header,
  children,
  maxHeaderHeight,
  maxHeaderWidth,
  additionalBlocks,
  revertPosition,
  subText,
  colDoggoOnMobile,
  hideDoggoOnMobile,
  ...rest
}: DNADoggoSaysProps) {
  console.log(header);
  const effect1 = useFadeInRight();
  const effect2 = useFadeInRight();
  return (
    <Container
      {...rest}
      revertPosition={revertPosition}
      colDoggoOnMobile={colDoggoOnMobile}
      style={{zIndex: 1}}
    >
      <animated.div
        style={{...effect1}}
        className={hideDoggoOnMobile ? 'hide-on-mobile img-cont' : 'img-cont'}
      >
        <img
          src={doctorDogImg}
          style={{
            minWidth: maxHeaderWidth || '200px',
            maxHeight: maxHeaderHeight || 'auto',
          }}
        />
        {(header || subText) && (
          <BlueBlock
            style={{margin: '-5px 0 0', textAlign: 'center', fontSize: '22px'}}
          >
            {subText ? subText : 'DNA Doggo'}
          </BlueBlock>
        )}
      </animated.div>
      <animated.div
        style={{
          ...effect2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {header && <Header>{header}</Header>}
        <Block>{children}</Block>
        <div>{additionalBlocks}</div>
      </animated.div>
    </Container>
  );
}

export default DNADoggoSays as FunctionComponent<DNADoggoSaysProps>;
