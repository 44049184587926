import React, {useRef} from 'react';
import {Container, LogoBlock, Sections} from './IntroPage.styles';
import RichnessImage from '../../assets/img/icons/Richness Score.svg';
import PetfoodIcon from '../../assets/img/icons/pet-food.png';
import BloodTestIcon from '../../assets/img/icons/blood-test.png';
import Logo from '../../assets/img/images/WHITE LOGO.png';
import {UserInfoDTO} from '../../dto/UserInfoDTO';

function IntroPage({userInfo, ...rest}: any) {
  const ref = useRef<HTMLInputElement>(null);

  const renderLogo = (userInfo: UserInfoDTO) => {
    if (!userInfo?.stakeholder_info?.logo) {
      return <img src={Logo}/>;
    }

    return (
      <>
        <img src={userInfo.stakeholder_info.logo}/>
        <div
          style={{
            fontSize: '30px',
            color: 'white',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          X
        </div>
        <img src={Logo}/>
      </>
    );
  };

  return (
    <Container ref={ref} {...rest}>
      {userInfo && (
        <h1 className="header">{userInfo.dog_name}’s Microbiome Report</h1>
      )}
      <Sections>
        <div className="row">
          <div
            className="section microbiom"
            onClick={() => rest.setActiveTab(3)}
          >
            <img src={BloodTestIcon}/>
            <div className="text">Microbiome Results</div>
          </div>
          <div
            className="section recommendation"
            onClick={() => rest.setActiveTab(12)}
          >
            <img src={PetfoodIcon}/>
            <div className="text">Food Recommendations</div>
          </div>
          <div
            className="section moreData"
            onClick={() =>
              rest.isDisabledRecommendationPage ?
                rest.setActiveTab(13) :
                rest.setActiveTab(14)
            }
          >
            <img src={RichnessImage}/>
            <div className="text">
              EXTRA: More data
            </div>
          </div>
        </div>
      </Sections>
      <LogoBlock>
        {renderLogo(userInfo)}
      </LogoBlock>

    </Container>
  );
}

export default React.memo(IntroPage);
