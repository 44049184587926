import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {animated} from 'react-spring';
import {DEVICE} from '../../const/devices';

export const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${COLORS.blue};
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
  padding: 3rem 3rem 5rem 3rem;

  .greetings {
    display: block;
    width: 100%;
    text-align: center;
  }

  .body-text {
    margin-top: 115px;

    @media ${DEVICE.tablet} {
      margin-top: 0;
      text-align: center;
    }
  }

  .bottomImage {
    width: auto;
		min-height: 8rem;
    margin-left: auto;
    max-width: 700px;
    flex-grow: 1;
    max-height: 100%;
    overflow: hidden;
    object-fit: contain;

    @media ${DEVICE.laptop} {
			display: none;
    }
  }

  @media ${DEVICE.tablet} {
		padding: 1rem;
  }
`;

export const Layout = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	text-align: right;
	color: ${COLORS.white};
	
	h1 {
		font-size: 78px;
		font-family: 'Bevan', cursive;
    font-weight: 400;
    white-space: nowrap;
		line-height: 5rem;
		color: ${COLORS.white};
	}
	span, tr{
		font-size: 30px;
		font-family: "Avenir Roman";

	}

  @media ${DEVICE.tablet} {
    h1 {
      font-size: 62px;
    }
    &.mobile-no-layout {
      flex-direction: column;
    }
    span, tr{
      font-size: 26px;
    }
  }
  @media ${DEVICE.mobile} {
    h1 {
      font-size: 58px;
    }
  }
	`;

export const Image = styled.div`
  border: 6px solid ${COLORS.white};
  border-radius: 25px;
  padding: 5px;
  margin: 1rem auto auto;
  width: fit-content;

  > img {
    width: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 20px;
  }
`;

export const Text = styled.div`
	margin-top: 1rem;
	font-size: 20px;
	color: ${COLORS.white};
	margin-left: 1rem;
	`;

export const Table = styled(animated.div)`
	background: ${COLORS.white};
	border-radius: 30px;
	padding: 1rem 1rem 0;
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
  margin: 0 0 5rem 0;
	
	> div {
    > h1 {
      font-family: 'Bevan', cursive;
      font-weight: 400;
      font-size: 32px;
      padding: 0.5rem 1rem;
      background: ${COLORS.pink};
      color: ${COLORS.white};
			text-align: center;
    }
		
		> div {
			color: ${COLORS.blue};
			
			div {
				padding: 1rem;
      }
			
			span, tr {
				text-align: left;
        font-size: 22px;
				margin-bottom: 4px;


        display: flex;
        td {
          flex-grow: 1;

          text-align: left;
          &:not(:first-of-type) {
            text-align: right;
						margin-left: 10px;
          }
        }
			}
			h3 {
				font-size: 22px;
				text-align: left;
			}
		}
  }

  @media ${DEVICE.mobile} {
		flex-direction: column;
  }
	`;
