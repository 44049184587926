import React, {FunctionComponent} from 'react';
import {Container} from './SectionD.styles';
import {HeaderBlock, TextBlock} from '../../../components/Blocks';
import {PageProps} from '../../../dto/PageType';
import RichnessImage from '../../../assets/img/icons/Richness Score.svg';
import {COLORS} from '../../../const/colors';

function SectionD({userInfo, ...rest}: PageProps) {
  return (
    <Container {...rest}>
      <HeaderBlock color={COLORS.green} shadowColor={COLORS.darkGreen}>
        <div
          className="section-block"
          style={{
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img className={'icon hide-on-mobile'} src={RichnessImage} />
          <span className="section">Section D</span>
        </div>
        <h1 className="header-title">The Extras: More Microbiome Data</h1>
      </HeaderBlock>
      <TextBlock color={COLORS.green}>
        <div>
          <p>
              We could build a 100 page report using data from {userInfo.dog_name}’s microbiome - it’s like a hidden treasure box! But, we’ve already given you a personalised summary using the scores calculated by our pawfessors.
          </p>
          <p>
          If, like us, you’re fascinated by the microbiome - read on for some further insights!
          </p>
        </div>
      </TextBlock>
    </Container>
  );
}

export default React.memo(SectionD) as FunctionComponent<PageProps>;
