import React, {FunctionComponent} from 'react';
import {Block, Container, Header} from './BlockWithHeader.styles';
import {BlockWithHeaderProps} from './index';
import {useScale} from '../../hooks/animation';

function BlockWithHeader({
  header,
  headerColor,
  blockColor,
  children,
  icon,
  ...rest
}: BlockWithHeaderProps) {
  const effect = useScale();
  return (
    <Container {...rest}>
      <Header color={headerColor} style={effect}>
        {icon && <img src={icon} />}
        {header}
      </Header>
      <Block className={'block-with-header-body'} color={blockColor}>
        {children}
      </Block>
    </Container>
  );
}

export default BlockWithHeader as FunctionComponent<BlockWithHeaderProps>;
