import React, {FunctionComponent, useState} from 'react';
import {Container, Image} from './ChondroitinPage.styles';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import {PageProps} from '../../../dto/PageType';
import BaseDietIcon from '../../../assets/img/icons/Base Diet.svg';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import {COLORS} from '../../../const/colors';
import PounceProtectImage from '../../../assets/img/images/Pounce + Protect.webp';
import Blob from '../../../components/Blob/Blob';
import {BottomBlock, FoodForPet} from '../../../components/Blocks';

function ChondroitinPage({userInfo, ...rest}: PageProps) {
  const values = [
    {value: 0, label: 'Inefficient'},
    {value: 5, label: 'Ok'},
    {value: 10, label: 'Efficient'},
  ];
  const [idImageToShow, setIsImageToShow] = useState<string>();

  return (
    <Container {...rest}>
      {userInfo.dog_image && (
        <Image>
          <img src={userInfo.dog_image}/>
        </Image>
      )}
      <div>
        <BlockWithHeader
          header={'Chondroitin Score'}
          icon={BaseDietIcon}
          headerColor={COLORS.darkBrown}
        >
          <LinearGraph
            value={
              userInfo?.chondroitin_score ? userInfo.chondroitin_score : 'N/A'
            }
            dogName={userInfo.dog_name}
            values={values}
          />
          {userInfo?.chondroitin_score ? (
            <>
              {userInfo.chondroitin_score >= 7 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}’s gut is{' '}
                    <span style={{color: '#95b660'}}>efficient</span> at
                    breaking down chondroitin .
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}’s gut has a lot of bacteria that
                    help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} break down and digest chondroitin –
                    essential for joint support. Ensure that they are being provided with chondroitin sources regularly.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Low chondroitin levels can lead to weak joints & movement conditions such as dysplasia &
                    arthritis. {userInfo.dog_name} needs a regular dose of chondroitin to support this.
                  </div>
                </>
              )}
              {userInfo.chondroitin_score < 7 &&
                userInfo.chondroitin_score > 4 && (
                  <>
                    <div style={{margin: '2rem 0'}}>
                      {userInfo.dog_name}’s gut is{' '}
                      <span style={{color: '#ecb33d'}}>ok</span> at breaking
                      down chondroitin.
                    </div>
                    <div className="subtitle">What does this mean?</div>
                    <div style={{marginBottom: '2rem'}}>
                      {userInfo.dog_name}’s gut has some bacteria that
                      help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} break down and digest chondroitin –
                      essential for joint support. Ensure that {userInfo.dog_name} is getting a boost of chondroitin.
                    </div>
                    <div className="subtitle">Why should I care?</div>
                    <div>
                      Low chondroitin levels can lead to weak joints & movement conditions such as dysplasia &
                      arthritis. {userInfo.dog_name} needs an increase of chondroitin
                      in {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet to prevent this.
                    </div>
                  </>
                )}
              {userInfo.chondroitin_score <= 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}’s gut is{' '}
                    <span style={{color: '#ed3560'}}>inefficient </span> at
                    breaking down chondroitin.
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}’s gut has very few bacteria that
                    help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} break down and digest chondroitin –
                    essential for joint support. Ensure that {userInfo.dog_name} gets an elevated amount of chondroitin
                    to support {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} movement.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Low chondroitin levels can lead to weak joints & movement conditions such as dysplasia &
                    arthritis. {userInfo.dog_name} needs a significant increase of chondroitin
                    in {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet to prevent this.
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div style={{margin: '2rem 0'}}>
                There is no information for this in {userInfo.dog_name}’s gut!
              </div>
              <div className="subtitle">What does this mean?</div>
              <div style={{marginBottom: '2rem'}}>
                Based on our method of analysis, it seems like {userInfo.dog_name} has very little amounts of bacteria
                that help them breakdown chondroitin! This doesn't mean that they don't have any of these bacteria - but
                it is likely that they need chondroitin support. Make sure to bring this up during your consultation
                with our team <a href={'mailto:hello@treattherapeutics.com'}>here</a> to find out more.
              </div>
              <div className="subtitle">Why should I care?</div>
              <div>
                Low chondroitin levels can lead to weak joints & movement
                conditions such as dysplasia & arthritis. {userInfo.dog_name} needs
                a regular dose of chondroitin to support this.
              </div>
            </>
          )}
          <BottomBlock>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {userInfo?.chondroitin_food_recommendation?.length ? (
                <>
                  <div className="subtitle">What to feed:</div>
                  {userInfo?.chondroitin_food_recommendation.map(
                    (elem: any) => (
                      <div
                        key={elem.id}
                        style={{position: 'relative'}}
                        onMouseOver={(e) => (
                          e.stopPropagation(), setIsImageToShow(elem.id)
                        )}
                        onMouseLeave={() => setIsImageToShow('')}
                      >
                        <a href={elem.link_url} target="_blank" rel="noreferrer">
                          <img
                            src={elem.image_url}
                            className={`food-image ${
                              idImageToShow === elem.id && 'hover'
                            }`}
                          />
                          {idImageToShow === elem.id && (
                            <div className="text-for-icon">{elem.name}</div>
                          )}
                        </a>
                      </div>
                    )
                  )}
                </>
              ) : (
                ''
              )}
            </div>
            {userInfo.show_supplements && <>
              {
                userInfo.chondroitin_score ? (
                  userInfo.chondroitin_score < 7 ? (
                    <FoodForPet
                      color="#a2d19a"
                      btnColor="#3a5949"
                      backGroundColor="#496f5b"
                    >
                      <div className="text">
                        Low chondroitin means weaker joints & more pain
                        <br/> <br/>
                        <span>
                        <span style={{color: '#3a5949'}}>POUNCE+PROTECT</span>{' '}
                          helps this.
                      </span>
                        <a
                          href="https://www.treattherapeutics.com/products/pounce-protect?utm_source=report&utm_medium=button&utm_campaign=report-rec-pounce&utm_id=report-rec-pounce"
                          target="_blank"
                          rel="noreferrer"
                          className="learnmore-btn"
                        >
                          Learn more
                        </a>
                      </div>
                      <img src={PounceProtectImage}/>
                    </FoodForPet>
                  ) : (
                    ''
                  )
                ) : (
                  <FoodForPet
                    color="#a2d19a"
                    btnColor="#3a5949"
                    backGroundColor="#496f5b"
                  >
                    <div className="text">
                      Low chondroitin means weaker joints & more pain
                      <br/> <br/>
                      <span>
                      <span style={{color: '#3a5949'}}>POUNCE+PROTECT</span>{' '}
                        helps this.
                    </span>
                      <a
                        href="https://www.treattherapeutics.com/products/pounce-protect?utm_source=report&utm_medium=button&utm_campaign=report-rec-pounce&utm_id=report-rec-pounce"
                        target="_blank"
                        rel="noreferrer"
                        className="learnmore-btn"
                      >
                        Learn more
                      </a>
                    </div>
                    <img src={PounceProtectImage}/>
                  </FoodForPet>
                )
              }
            </>
            }
          </BottomBlock>
        </BlockWithHeader>
      </div>
      <Blob variant={3}/>
    </Container>
  );
}

export default React.memo(ChondroitinPage) as FunctionComponent<PageProps>;
