import React, {FunctionComponent, useState} from 'react';
import {Container, Image} from './CarbAndFiberPage.styles';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import {PageProps} from '../../../dto/PageType';
import BaseDietIcon from '../../../assets/img/icons/Base Diet.svg';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import GUTAPPETITEImage from '../../../assets/img/images/GUT+APPETITE.webp';
import {COLORS} from '../../../const/colors';
import Blob from '../../../components/Blob/Blob';
import {BottomBlock, FoodForPet} from '../../../components/Blocks';

function CarbAndFiberPage({userInfo, ...rest}: PageProps) {
  const values = [
    {value: 0, label: 'Inefficient'},
    {value: 5, label: 'Ok'},
    {value: 10, label: 'Efficient'},
  ];
  const [idImageToShow, setIsImageToShow] = useState<string>();

  return (
    <Container {...rest}>
      {userInfo.dog_image && (
        <Image>
          <img src={userInfo.dog_image}/>
        </Image>
      )}
      <div>
        <BlockWithHeader
          header={'Fibre Score'}
          icon={BaseDietIcon}
          headerColor={COLORS.darkBrown}
        >
          {userInfo.carbohydrates_score ? (
            <>
              <LinearGraph
                value={userInfo.carbohydrates_score}
                dogName={userInfo.dog_name}
                values={values}
              />
              {userInfo.carbohydrates_score > 6 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut bacteria are{' '}
                    <span style={{color: '#95b660'}}>efficient</span> at breaking down fibres!
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s sample contains{' '}
                    <span style={{color: '#95b660'}}>a lot of useful bacteria</span>{' '}
                    that help break down and digest fibre.
                    This means that they absorb a lot of fibre from their diet with little effort!
                    It is important to make sure {userInfo.dog_name} doesn’t absorb more fibre than is good for them.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Sufficient fibre is super important for feeding good bacteria in the gut.
                    BUT, excessively high fibre levels can prevent nutrients from being absorbed.
                    If {userInfo.dog_name}'s diet is fibre rich, you may want to consider changing their
                    food to raw instead - discuss further on your consultation!
                  </div>
                </>
              )}
              {userInfo.carbohydrates_score <= 6 &&
                userInfo.carbohydrates_score >= 4 && (
                  <>
                    <div style={{margin: '2rem 0'}}>
                      {userInfo.dog_name}'s gut bacteria are{' '}
                      <span style={{color: '#ecb33d'}}>ok</span> at breaking down fibres.
                    </div>
                    <div className="subtitle">What does this mean?</div>
                    <div style={{marginBottom: '2rem'}}>
                      {userInfo.dog_name}'s sample contains some bacteria that help break down and digest fibre.
                      This means that although {userInfo.dog_name} is probably absorbing sufficient fibre
                      from {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet, their gut has to work a little harder than others to do so.
                    </div>
                    <div className="subtitle">Why should I care?</div>
                    <div>
                      The good bacteria in {userInfo.dog_name}'s gut feed off fibre.{' '}
                      {userInfo.dog_name} is ok at breaking down fibre, watch our for signs of low fibre:{' '}
                      <span style={{color: '#ecb33d'}}>diarrhea</span>{' '}
                      and{' '}
                      <span style={{color: '#ecb33d'}}>constipation.</span>{' '}
                      Make sure {userInfo.dog_name} gets some fruit & vegetables or powdered fibre sources to
                      prevent this score from dropping!
                    </div>
                  </>
                )}
              {userInfo.carbohydrates_score < 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}’s gut bacteria are{' '}
                    <span style={{color: '#ed3560'}}>inefficient</span> at
                    breaking down fibres.
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s gut has very few bacteria that help break down and digest fibre.
                    This means that even though {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet may already contain fibre, {userInfo.dog_name}'s gut has to
                    use a relatively high amount of effort to break down this fibre.
                    {userInfo.dog_name} may not be absorbing enough fibre for optimal health.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Low fibre levels cause gut bacterial imbalances because the good bacteria in {userInfo.dog_name}'s{' '}
                    gut feed off fibre.
                    Low fibre is at the root of <span style={{color: '#ed3560'}}>diarrhea</span> and{' '}
                    <span style={{color: '#ed3560'}}>constipation</span> as well as long term health effects.
                    {userInfo.dog_name} needs some fruit & vegetables or powdered fibre sources to boost this score!
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <LinearGraph
                value={0}
                dogName={userInfo.dog_name}
                values={values}
              />
              <>
                <div style={{margin: '2rem 0'}}>
                  {userInfo.dog_name}’s gut bacteria are{' '}
                  <span style={{color: '#ed3560'}}>INEFFICIENT </span> at
                  breaking down carbohydrates, including Sugars, Starches and Fibres.
                </div>
                <div className="subtitle">What does this mean?</div>
                <div style={{marginBottom: '2rem'}}>
                  {userInfo.dog_name}’s gut has very few bacteria that
                  help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} break down and digest carbohydrates.
                  This means that {userInfo.dog_gender === 'FEMALE' ? 'she' : 'he'} gets carbohydrates
                  from {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet with significant
                  effort. {userInfo.dog_name} may not be absorbing enough
                  carbohydrates for {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} health.
                </div>
                <div className="subtitle">Why should I care?</div>
                <div>
                  Low fibre levels cause gut imbalances – diarrhea and
                  constipation as well as long term health effects.{' '}
                  {userInfo.dog_name} needs some natural fruit & vegetables as
                  well as fibre sources to boost this.{' '}
                </div>
              </>
            </>
          )}
          <BottomBlock>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {userInfo?.carbohydrates_food_recommendation?.length ? (
                <>
                  <div className="subtitle">What to feed:</div>
                  {userInfo?.carbohydrates_food_recommendation.map(
                    (elem: any) => (
                      <div
                        key={elem.id}
                        style={{position: 'relative'}}
                        onMouseOver={(e) => (
                          e.stopPropagation(), setIsImageToShow(elem.id)
                        )}
                        onMouseLeave={() => setIsImageToShow('')}
                      >
                        <a href={elem.link_url} target="_blank" rel="noreferrer">
                          <img
                            src={elem.image_url}
                            className={`food-image ${
                              idImageToShow === elem.id && 'hover'
                            }`}
                          />
                          {idImageToShow === elem.id && (
                            <div className="text-for-icon">{elem.name}</div>
                          )}
                        </a>
                      </div>
                    )
                  )}
                </>
              ) : (
                ''
              )}
            </div>
            {userInfo.carbohydrates_score && userInfo.show_supplements ? (
              userInfo.carbohydrates_score < 7 ? (
                <FoodForPet
                  color="#dfb870"
                  btnColor="#a95743"
                  backGroundColor="#b65f49"
                >
                  <div className="text">
                    Hmm – looks like {userInfo.dog_name}
                    <br/> could use some support. <br/> <br/>
                    <span>
                      <span style={{color: '#a95743'}}>GUT + APPETITE</span>{' '}
                      would help!
                    </span>
                    <a
                      href="https://www.treattherapeutics.com/products/gut-appetite?utm_source=report&utm_medium=button&utm_campaign=report-rec-gut&utm_id=report-rec-gut"
                      target="_blank"
                      rel="noreferrer"
                      className="learnmore-btn"
                    >
                      Learn more
                    </a>
                  </div>
                  <img src={GUTAPPETITEImage}/>
                </FoodForPet>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </BottomBlock>
        </BlockWithHeader>
      </div>
      <Blob variant={3}/>
    </Container>
  );
}

export default React.memo(CarbAndFiberPage) as FunctionComponent<PageProps>;
