import React, {FunctionComponent} from 'react';
import {Container, Image} from './NutritionalPage.styles';
import {Block, SmallTextBlock} from '../../../components/Blocks';
import BaseDietIcon from '../../../assets/img/icons/Base Diet.svg';
import {PageProps} from '../../../dto/PageType';
import {COLORS} from '../../../const/colors';

function NutritionalPage({userInfo, ...rest}: PageProps) {
  return (
    <>
      <Container {...rest}>
        {userInfo.dog_image && (
          <Image>
            <img src={userInfo.dog_image} />
          </Image>
        )}
        <Block color={COLORS.darkBrown} shadowColor={COLORS.brown}>
          <div
            className="section-block"
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img className={'icon hide-on-mobile'} src={BaseDietIcon} />
            <span className="section">Part 2</span>
          </div>
          <h1 className="header-title">Nutritional Pathway Results</h1>
        </Block>
        <SmallTextBlock color={COLORS.darkBrown}>
          Understand {userInfo.dog_name}’s nutritional needs and how to support
          them
        </SmallTextBlock>
      </Container>
    </>
  );
}

export default React.memo(NutritionalPage) as FunctionComponent<PageProps>;
