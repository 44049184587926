import styled from 'styled-components';
import {COLORS} from '../../const/colors';

export const Container = styled.div<{color: string}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({color}) => color};
  z-index: 100;
  font-family: Blogh;
  position: relative;
  padding-bottom: 0.5rem;

  @media (min-width: 768px) {
    justify-content: center;

    .arrow > span {
      top: 50%;
      position: fixed;
    }

    .left {
      left: 0.2rem;

      > span {
        left: 10px;
      }
    }

    .right {
      right: 0.2rem;

      > span {
        right: 10px;
      }
    }
  }
`;

export const PaginationItem = styled.div<{disabled?: boolean, active?: boolean}>`
  padding: 0.5rem 1rem;
  font-size: 32px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  color: ${({disabled}) => disabled ? COLORS.grey : COLORS.white};
  font-weight: bold;

  .reserve-consultation-button {
    font-family: 'Avenir Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
    background: #db3259;
    right: 50px;
    font-size: 21px;
    padding: 4px 16px;
    border-radius: 15px;
    text-decoration: none;
    color: white;
    transition: all 0.5s;
    justify-content: center;
    align-items: center;
    display: flex;

    &:hover {
      color: black;
      background: #f7557d;
      transition: all 0.5s;
    }

    @media (max-width: 1920px) {
      font-size: 15px;
      top: 12%;
      width: 210px;
      padding: 8px 28px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      top: 13%;
      width: 210px;
      padding: 8px 28px;
    }
    @media (max-width: 800px) {
      font-size: 15px;
      top: 15%;
      width: 210px;
      padding: 8px 28px;
    }
    @media (max-width: 750px) {
      font-size: 18px;
      top: -70%;
      right: 30%;
      width: 250px;
      padding: 8px 28px;
    }
    @media (max-width: 625px) {
      font-size: 18px;
      top: -70%;
      right: 22%;
      width: 250px;
      padding: 8px 28px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
      top: -75%;
      right: 9%;
      width: 250px;
      padding: 8px 28px;
    }
  }


  &.error {
    color: ${COLORS.orange};
  }

  @media (min-width: 768px) {
    &.arrow {
      font-size: 52px;
      height: 100%;
      position: fixed;
      top: 0;
    }
  }
`;

export const Loader = styled.img`
	width: 40px;
	height: 40px;
	margin: 10px auto;
	display: block;
`;

export const Popover = styled.div<{open?: boolean, background?: string}>`
  position: absolute;
  border-radius: 20px;
  background: ${({background}) => background ? background : COLORS.blue};
  padding: 1rem;
  display: ${({open}) => open ? 'block' : 'none'};
  color: ${COLORS.white};
  bottom: 3.5rem;
  max-height: 80vh;
  overflow: auto;

  h3 {
    cursor: pointer;
    font-size: 20px;
    font-family: 'Avenir Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 0.5rem;
  }
`;

export const Progress = styled.div<{value: number, maxValue: number}>`
	width: ${({value, maxValue}) => value/maxValue * 100}%;
	background: ${COLORS.white};
	height: 10px;
	bottom: 3px;
  position: absolute;
	left: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
`;

export const ProgressBack = styled.div`
  background: ${COLORS.white};
  height: 10px;
  bottom: 3px;
  position: absolute;
  left: 0;
	width: 100%;
	opacity: 0.2;
`;
