import React, {FunctionComponent, useState} from 'react';
import {Container, Image} from './SCFAPage.styles';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import {PageProps} from '../../../dto/PageType';
import BaseDietIcon from '../../../assets/img/icons/Base Diet.svg';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import {COLORS} from '../../../const/colors';
import PounceProtectImage from '../../../assets/img/images/Pounce + Protect.webp';
import Blob from '../../../components/Blob/Blob';
import {BottomBlock, FoodForPet} from '../../../components/Blocks';

function SCFAPage({userInfo, ...rest}: PageProps) {
  const values = [
    {value: 0, label: 'Inefficient'},
    {value: 5, label: 'Ok'},
    {value: 10, label: 'Efficient'},
  ];
  const [idImageToShow, setIsImageToShow] = useState<string>();

  return (
    <Container {...rest}>
      {userInfo.dog_image && (
        <Image>
          <img src={userInfo.dog_image}/>
        </Image>
      )}
      <div>
        <BlockWithHeader
          header={'Gut Shield Score: Short Chain Fatty Acids'}
          icon={BaseDietIcon}
          headerColor={COLORS.darkBrown}
        >
          <LinearGraph
            value={
              userInfo?.scfa_score ? userInfo.scfa_score : 'N/A'
            }
            dogName={userInfo.dog_name}
            values={values}
          />
          {userInfo?.scfa_score ? (
            <>
              {userInfo.scfa_score >= 7 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}’s gut is{' '}
                    <span style={{color: '#95b660'}}>efficient</span> at
                    producing Short Chain Fatty Acids (SCFAs).
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}’s sample contains very few bacteria that help him produce
                      Short Chain Fatty Acids (SCFAs). {userInfo.dog_gender === 'FEMALE' ? 'Her' : 'His'} gut
                      has to do a lot of work to produce these important nutritents - {userInfo.dog_name} may
                      not be producing enough to support his overall health!
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                      SCFAs (short-chain fatty acids) are essential molecules produced by the bacteria
                      in {userInfo.dog_name}’s gut. These compounds, including acetate, propionate, and butyrate,
                      play multiple roles in maintaining gut health and overall well-being.
                      They provide energy to the cells lining the colon, promote a robust gut barrier, control
                      immune responses, regulate energy levels, and even reduce inflammation.
                  </div>
                  <div>
                      A high score indicates that {userInfo.dog_name} has a solid gut lining.{' '}
                      {userInfo.dog_gender === 'FEMALE' ? 'Her' : 'His'} gut is a strong protective shield and
                      doesn't let problematic molecules pass!
                  </div>
                </>
              )}
              {userInfo.scfa_score < 7 && userInfo.scfa_score > 4 && (
                  <>
                    <div style={{margin: '2rem 0'}}>
                      {userInfo.dog_name}’s gut is{' '}
                      <span style={{color: '#ecb33d'}}>moderately efficient</span> at
                      producing Short Chain Fatty Acids (SCFAs).
                    </div>
                    <div className="subtitle">What does this mean?</div>
                    <div style={{marginBottom: '2rem'}}>
                        {userInfo.dog_name}’s sample contains some bacteria that help him produce Short Chain Fatty Acids (SCFAs).{' '}
                        {userInfo.dog_gender === 'FEMALE' ? 'Her' : 'His'} gut has to do a lot of work to produce these
                        important nutritents - {userInfo.dog_name} may not be producing enough to support his overall health!
                    </div>
                    <div className="subtitle">Why should I care?</div>
                    <div>
                        SCFAs (short-chain fatty acids) are essential molecules produced by the bacteria
                        in {userInfo.dog_name}’s gut. These compounds, including acetate, propionate, and butyrate,
                        play multiple roles in maintaining gut health and overall well-being.
                        They provide energy to the cells lining the colon, promote a robust gut barrier,
                        control immune responses, regulate energy levels, and even reduce inflammation.
                    </div>
                      <br />
                    <div>
                        A moderate score indicates that {userInfo.dog_name} is at potential risk for long-term
                        health concerns; {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} gut can still be made more robust to prevent problematic molecules
                        from sneaking into their bloodstream!
                    </div>
                  </>
                )}
              {userInfo.scfa_score <= 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}’s gut is{' '}
                    <span style={{color: '#ed3560'}}>inefficient</span> at
                    producing Short Chain Fatty Acids (SCFAs).
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}’s sample contains very few bacteria that
                    help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} produce Short Chain Fatty Acids (SCFAs).
                    His gut has to do a lot of work to produce these important nutritents - {userInfo.dog_name}{' '}
                    may not be producing enough to support {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} overall health!
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                      SCFAs (short-chain fatty acids) are essential molecules produced by the bacteria in {userInfo.dog_name}’s gut.
                      These compounds, including acetate, propionate, and butyrate, play multiple roles in
                      maintaining gut health and overall well-being.
                      They provide energy to the cells lining the colon, promote a robust gut barrier, control immune
                      responses, regulate energy levels, and even reduce inflammation.
                  </div>
                  <div>
                      A low score indicates that {userInfo.dog_name} is at risk for long-term health concerns;
                      creating weakness in their gut and letting problematic molecules sneak into their bloodstream.
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div style={{margin: '2rem 0'}}>
                There is no information for this in {userInfo.dog_name}’s gut!
              </div>
              <div className="subtitle">What does this mean?</div>
              <div style={{marginBottom: '2rem'}}>
                  {userInfo.dog_name}’s sample contains very few bacteria that
                  help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} produce Short Chain Fatty Acids (SCFAs).
                  His gut has to do a lot of work to produce these important nutritents - {userInfo.dog_name}{' '}
                  may not be producing enough to support {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} overall health!
                  Make sure to bring this up during your consultation
                  with our team <a href={'mailto:hello@treattherapeutics.com'}>here</a> to find out more.
              </div>
              <div className="subtitle">Why should I care?</div>
              <div>
                  SCFAs (short-chain fatty acids) are essential molecules produced by the bacteria in {userInfo.dog_name}’s gut.
                  These compounds, including acetate, propionate, and butyrate, play multiple roles in
                  maintaining gut health and overall well-being.
                  They provide energy to the cells lining the colon, promote a robust gut barrier, control immune
                  responses, regulate energy levels, and even reduce inflammation.
              </div>
              <div>
                  A low score indicates that {userInfo.dog_name} is at risk for long-term health concerns;
                  creating weakness in their gut and letting problematic molecules sneak into their bloodstream.
              </div>
            </>
          )}
        </BlockWithHeader>
      </div>
      <Blob variant={3}/>
    </Container>
  );
}

export default React.memo(SCFAPage) as FunctionComponent<PageProps>;
