import React, {FunctionComponent, useState} from 'react';
import {Container} from '../RichnessGraphPage/RichnessGraphPage.styles';
import {COLORS} from '../../../const/colors';
import BlockWithHeader from '../../../components/BlockWithHeader';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import EvennessIcon from '../../../assets/img/icons/Evenness Score.svg';
import GUTAPPETITEImage from '../../../assets/img/images/GUT+APPETITE.webp';
import {BottomBlock, FoodForPet} from '../../../components/Blocks';
import {PageProps} from '../../../dto/PageType';

function EvennessGraphPage({userInfo, ...rest}: PageProps) {
  const avgEveness = userInfo.avg_evenness || 86;
  const [idImageToShow, setIsImageToShow] = useState<string>();

  return (
    <Container {...rest}>
      <div>
        <BlockWithHeader
          header={'EVENNESS'}
          headerColor={COLORS.brown}
          icon={EvennessIcon}
        >
          {userInfo.evenness2 ? (
            <>
              <LinearGraph
                value={userInfo.evenness2}
                dogName={userInfo.dog_name}

              />
              {userInfo.evenness2 < 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut bacteria population is{' '}
                    <span style={{color: '#ed3560'}}>imbalanced</span>.
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    There are thousands of different bacterial populations in {userInfo.dog_name}'s gut.
                    {' '} <span style={{color: '#ed3560'}}>A poor evenness score</span> means that the size of most of these populations {' '} <span style={{color: '#ed3560'}}>are not the same</span>,
                    meaning that their digestive ecosystem is at risk! Ideally, we want the different
                    populations of bacteria to be relatively balanced.
                    Improving this score is vital to {userInfo.dog_name}'s overall health.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Low evenness puts {userInfo.dog_name} at risk for chronic
                    diseases. Boost the amount of fibers in {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet
                    significantly to support {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'}. If {userInfo.dog_name} is on
                    regular antibiotics, consider reducing or removing them. You
                    have the ability to protect {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'}!
                  </div>
                </>
              )}
              {userInfo.evenness2 <= 6 && userInfo.evenness2 >= 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut bacteria population is{' '}
                    <span style={{color: '#ecb33d'}}>moderately evenly balanced.</span>
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                  There are thousands of different types of bacteria in {userInfo.dog_name}'s gut.
                  A {' '}<span style={{color: '#ecb33d'}}>moderate evenness score</span> means
                  that the size of {' '}<span style={{color: '#ecb33d'}}>most</span> of these
                  populations are well balanced meaning that their digestive ecosystem
                  is decently balanced!
                  There is still room for improvement - a high evenness score is
                  vital to overall health.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Lower evenness puts {userInfo.dog_name} at risk for chronic diseases.
                    Boost the amount of fibers in their diet to further support them –
                    you have the ability to protect them!
                  </div>
                </>
              )}
              {userInfo.evenness2 > 6 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut bacteria population is{' '}
                    <span style={{color: '#95b660'}}>evenly balanced.</span>
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    There are thousands of different bacterial populations
                    in {userInfo.dog_name}'s gut.
                    A {' '}<span style={{color: '#95b660'}}>high evenness score</span> means
                    that the size of these populations are <span style={{color: '#95b660'}}>roughly the same,</span>{' '}
                    meaning that their digestive ecosystem is balanced!
                    &nbsp;{userInfo.dog_name} is well protected from the inside out.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Low evenness would put {userInfo.dog_name} at risk for chronic diseases.
                    Typically, a high fibre diet is great at supporting evenly balanced
                    growth of bacteria - you've got this covered!
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <>
                <div style={{margin: '2rem 0'}}>
                  {userInfo.dog_name}’s gut bacteria population is{' '}
                  <span style={{color: '#ed3560'}}>imbalanced</span>.
                </div>
                <div className="subtitle">What does this mean?</div>
                <div style={{marginBottom: '2rem'}}>
                  There are thousands of different types of bacteria in{' '}
                  {userInfo.dog_name}’s gut. A poor evenness score means that the size of
                  most of these populations are not the same meaning that{' '}
                  {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} digestive
                  ecosystem is at risk! Improving this score is vital to their
                  overall health.
                </div>
                <div className="subtitle">Why should I care?</div>
                <div>
                  Low evenness puts {userInfo.dog_name} at risk for chronic
                  diseases. Boost the amount of fibers in {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet
                  significantly to support {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'}. If {userInfo.dog_name} is on
                  regular antibiotics, consider reducing or removing them. You
                  have the ability to protect {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'}!
                </div>
              </>
            </>
          )}
          <BottomBlock className="bottom-block">
            <div className="container">
              {userInfo?.evenness_food_recommendation?.length ? (
                <>
                  <div className="subtitle">What to feed:</div>
                  {userInfo?.evenness_food_recommendation.map((elem) => (
                    <div
                      key={elem.id}
                      style={{position: 'relative', borderRadius: '50%'}}
                      onMouseOver={(e) => (
                        e.stopPropagation(), setIsImageToShow(elem.id)
                      )}
                    >
                      <a href={elem.link_url} target="_blank" rel="noreferrer">
                        <img
                          src={elem.image_url}
                          className={`food-image ${
                            idImageToShow === elem.id && 'hover'
                          }`}
                        />
                        {idImageToShow === elem.id && (
                          <div className="text-for-icon">{elem.name}</div>
                        )}
                      </a>
                    </div>
                  ))}
                </>
              ) : (
                ''
              )}
            </div>
            {userInfo.evenness2 && userInfo?.show_supplements ? (
              userInfo.evenness2 < 6 ? (
                <FoodForPet
                  color="#dfb870"
                  btnColor="#a95743"
                  backGroundColor="#b65f49"
                >
                  <div className="text">
                    Hmm – looks like {userInfo.dog_name} could use some support.
                    <span style={{marginTop: '3px'}}>
                      <span style={{color: '#a95743'}}>GUT + APPETITE</span>{' '}
                      would help!
                    </span>
                    <a
                      href="https://www.treattherapeutics.com/products/gut-appetite?utm_source=report&utm_medium=button&utm_campaign=report-rec-gut&utm_id=report-rec-gut"
                      target="_blank"
                      rel="noreferrer"
                      className="learnmore-btn"
                    >
                      Learn more
                    </a>
                  </div>
                  <img src={GUTAPPETITEImage}/>
                </FoodForPet>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </BottomBlock>
        </BlockWithHeader>
      </div>
    </Container>
  );
}

export default React.memo(EvennessGraphPage) as FunctionComponent<PageProps>;
