import React, {FunctionComponent} from 'react';
import {Background, Container, Layout, Progress} from './LiveScope.styles';
import {LiveScoreProps} from './index';
import {COLORS} from '../../const/colors';
import {useScale} from '../../hooks/animation';

function LiveScore({
  value,
  text,
  max = 10,
  min = 0,
  color = COLORS.orange,
  ...rest
}: LiveScoreProps) {
  const effect = useScale();
  return (
    <Container {...rest}>
      <div className="label" style={{color: color}}>
        {text}
      </div>
      <Layout style={{...effect}}>
        <Progress width={(value / max) * 100} color={color}>
          {value}
        </Progress>
        <Background />
      </Layout>
      <Layout style={{justifyContent: 'space-between', color: color}}>
        <span>{min}</span>
        <span>{max}</span>
      </Layout>
    </Container>
  );
}

export default LiveScore as FunctionComponent<LiveScoreProps>;
