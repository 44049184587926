import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {MainContainer, WhiteBlock} from '../../components/Blocks';
import {DEVICE} from '../../const/devices';
import {animated} from 'react-spring';

export const Container = styled(MainContainer)`
  background-color: ${COLORS.pinkLight};
  padding: 1.5rem 1.5rem 0;
	
	> div {
		display: flex;
    flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	
	a {
		color: ${COLORS.blue}
	}

	.disclaimer {
		font-size: 56px !important;
		margin-bottom: 0px;

		@media(max-width:700px){
			font-size: 45px !important;
		}
		@media(max-width:460px){
			font-size: 30px !important;
			margin-bottom: -115px;
		}
	}
	
	@media ${DEVICE.mobile} {
		.smaller-header-mobile {
			margin-bottom: -6rem !important;
			font-size: 56px !important;
		}
	}
`;

export const RedText = styled.div`
	color: ${COLORS.pink};
	font-weight: bold;
	font-size: 28px;
	font-style: italic;
	margin-bottom: 0.5rem;
`;

export const DogsImage = styled(animated.img)`
	width: 100%;
	display: block;
	max-height: 500px;
	min-height: 200px;
	flex-grow: 1;
	position: relative;
	object-fit: contain;
	z-index: 1;
`;

export const BottomWhiteBlock = styled(WhiteBlock)`
border-radius: 30px 30px 0 0;
margin-top: -2rem;
margin-right: -3rem;
margin-left: -3rem;
font-size: 17px;
padding: 3rem 3rem 5rem 3rem;
z-index: 1;
`;

