import styled from 'styled-components';
import {COLORS} from '../../../const/colors';
import {MainCenterContainer} from '../../../components/Blocks';

export const Container = styled(MainCenterContainer)`
  background-color: ${COLORS.pinkLight};
  padding: 1rem 1.5rem 0;
	
	a {
		color: ${COLORS.blue};
	}

	.button-book-now{
		background: ${COLORS.green};
		padding: 19px 30px;
		font-size: 20px;
		font-family: 'Bevan', cursive;
		font-weight: 400;
		color: white;
		border: 1px solid ${COLORS.green};
		cursor: pointer;
		text-decoration: none;
		transition: all 0.3s;

		&:hover{
			color: black;
			transition: all 0.3s;
			background: #a8c8a2;
		}
	}
`;

export const Layout = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	
	h1 {
		text-align: center;
		width: 100%;
		font-size: 32px;
		font-weight: bold;
	}
	
	img {
		margin-right: 1rem;
		width: 40px;
		height: 40px;
		align-self: center;
		display: block;
	}
	
	> div > * {
		font-size: 20px;
	}
`;


export const ImageOverflow = styled.img`
	margin-right: -10px;
	position: fixed;
	display: block;
	width: 30%;
	right: 0;
	bottom: 60px;
  z-index: 10;
`;
