import styled from 'styled-components';
import {MainCenterContainer} from '../../components/Blocks';
import {COLORS} from '../../const/colors';

export const Container = styled(MainCenterContainer)`
  background-color: ${COLORS.pinkLight};


.bottom-subblock{
	margin: 10px 0;
}

.what-to-feed-text{
	@media(max-width:590px){
		font-size: 15px;
	}
	@media(max-width:480px){
		font-size: 10px;
	}
}
	
  .biggerText {
		font-weight: bold;
		font-size: 28px;
	}

  br:before {
    display: block;
    margin-top: 10px;
    content: "";
  }
	
	.img-cont {
		display: block;
		margin: auto;
	}
`;

export const Image = styled.div`
	position: absolute;
	top: -16px;
	z-index: 2;
	border: 6px solid ${COLORS.white};
	border-radius: 25px;
	padding: 5px;
	margin: 1rem 10% 1rem 80%;
	width: fit-content;

  @media(max-width:1025px){
	margin: 1rem 32% 1rem 63%;
  }
  @media(max-width:585px){
	margin: 1rem 27% 1rem 74%;
  }
  @media(max-width:488px){
	display: none;
  }
  > img {
    width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 20px;
	@media(max-width:598px){
		width: 80px;
    	max-height: 80px;
	  }
	@media(max-width:488px){
		display: none;
	  }
  }
`;

