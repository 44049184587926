import React, {FunctionComponent, useEffect, useState} from 'react';
import {Container} from './PickedFor.style';
import {PageProps} from '../../../dto/PageType';
import {RecommendationDTO} from '../../../dto/UserInfoDTO';

function PickedFor({userInfo, ...rest}: PageProps) {
  const [products, setProducts] = useState<RecommendationDTO[] | undefined>();

  useEffect(() => {
    if (!userInfo) return;
    setProducts(userInfo.recommendations);
  }, [userInfo]);

  const processTitle = (product: RecommendationDTO) => {
    const title = product?.name_of_product?.toUpperCase() ?? 'PLACEHOLDER PRODUCT NAME';
    return title.length > 40 ? `${title.slice(0, 40)}...` : title;
  };

  const processMainIngredient = (product: RecommendationDTO) => {
    const ingredient = product?.ingredients?.[0] ?? '';
    return ingredient.length > 20 ? `${ingredient.slice(0, 20)}...` : ingredient;
  };

  return (
    <Container {...rest}>
      <h1 className="header-title">
        Here’s what we’ve picked for {userInfo.dog_name}:
      </h1>
      <div className="row">
        {products &&
          products.map((product: RecommendationDTO) => (
            <div className="card" key={product.name_of_product}>
              <div className="card-title">{processTitle(product)}</div>
              <img src={product.image} alt={product.name_of_product}/>
              <div className="card-body">
                <ul>
                  <li>Protein: {product.crude_protein}</li>
                  <li>Fat: {product.crude_fat}</li>
                  <li>Fibre: {product.crude_fiber}</li>
                  <li>
                    Main ingredient: {processMainIngredient(product)}
                  </li>
                </ul>
              </div>
              <div className="card-button">
                <a href={product.url} target={'_blank'} rel="noreferrer">
                  See more here
                </a>
              </div>
            </div>
          ))}
      </div>
    </Container>
  );
}

export default React.memo(PickedFor) as FunctionComponent<PageProps>;
