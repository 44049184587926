import styled from 'styled-components';
import {COLORS} from '../../../const/colors';
import {MainCenterContainer} from '../../../components/Blocks';

export const Container = styled(MainCenterContainer)`
  background-color: ${COLORS.pinkLight};
  padding: 0.5rem 1.5rem;
	
	.imageChart {
		min-width: 150px;
		max-height: 500px;
		display: block;
		margin: auto;
		margin-top: 0.5rem;
	}

	
	}
`;
