import styled from 'styled-components';
import {COLORS} from '../../../const/colors';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import {MainContainer} from '../../../components/Blocks';

export const Container = styled(MainContainer)`
  background-color: ${COLORS.beige};
  padding: 0.5rem 1.5rem 3rem 1rem;
`;

export const WBlockWithHeader = styled(BlockWithHeader)`
	margin-top: -2rem;
	> div {
    padding: 39px;
    display: flex;
    flex-direction: column;

    .info-block {
			flex-grow: 1;
      align-self: center;
			width: 100%;
			> * {
        margin-bottom: 0.7rem;
			}
    }
	}
`;
