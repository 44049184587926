import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {DEVICE} from '../../const/devices';

export const Container = styled.div`
	
	@media ${DEVICE.mobile} {
    margin-left: -1rem;
    margin-right: -1rem;
	}
`;

export const BackgroundContainer = styled.div<{colors: string[]}>`
	border-radius: 30px;
	margin-top: 1rem;
	width: 100%;
	height: 50px;
	border: 4px solid ${COLORS.pinkLight};
	background: linear-gradient(to right, ${({colors}) => colors.join(',')});
`;

export const LabelsContainer = styled.div<{noLabelsMobile?: boolean}>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0.5rem 0 1rem;
	position: relative;
	background: ${COLORS.white};
  > div span:first-of-type {
    display: block;
    background: transparent;
    border-radius: 20px;
    padding: 10px 20px;
    z-index: 10;
    color: ${COLORS.white};
    font-weight: bold;
  }

  .values-graph{
	@media(max-width:766px){
		font-size: 15px;
		padding: 0px;
	}

  }

  > div span:last-of-type {
		font-weight: bold;
		text-align: center;
		display: block;
  }
	
	@media ${DEVICE.mobile} {
		> div {
      display: ${({noLabelsMobile}) => noLabelsMobile ? 'none': 'flex'} !important;
		}
    margin-bottom: ${({noLabelsMobile}) => noLabelsMobile ? '1rem': '0'} !important;
	}
`;

export const MainLabel = styled.div<{value: number}>`
  	border: 4px solid ${COLORS.pinkLight};
	padding: 1rem;
	text-align: center;
	border-radius: 20px;
	background: ${COLORS.white};
	font-weight: bold;
	width: fit-content;
	height: 70px;
	margin-top: -10px;
	box-sizing: border-box;
	margin-left: calc(${({value}) => value ? value >= 95 ? `${value}% - 100px` : `${value}% - ${value * 1.2}px` : value === 0 ? '' : '75% - 100px'});
	display: flex;
	flex-direction: column;
  	justify-content: space-around;
	font-size: 20px;
	
	div:last-of-type {
    font-size: 26px;
	}
	
`;

export const AvgLabel = styled.div<{value: number}>`
  position: relative;
	.mainBlock {
		width: fit-content;
		padding: 0.5rem;
		text-align: center;
    color: ${COLORS.grey};
    background: ${COLORS.white};
		border-radius: 10px;
		border: 4px solid ${COLORS.pinkLight};
		font-size: 20px;
		z-index: 5;
    margin-left: calc(${({value}) => value}% - 2rem);

    @media ${DEVICE.mobile} {
      margin-bottom: 3rem;
    }
		
		span {
			font-size: 28px;
      font-weight: bold;
		}
	}
	.arrow {
		position: absolute;
    border: solid ${COLORS.pinkLight};
    border-width: 0 4px 4px 0;
		border-radius: 5px;
    display: inline-block;
    padding: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
		background: ${COLORS.white};
    bottom: -10px;
    left: ${({value}) => value}%;
	}
`;

export const Images = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: -3rem;
	
	span {
		width: 25%;
		display: block;
    img{
      max-width: 230px;
			width: 100%;
			display: block;
			margin: auto;
    }
	}

  @media ${DEVICE.mobile} {
    img {
      display: none !important;
    }
  }
`;
