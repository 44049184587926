import styled from 'styled-components';
import {COLORS} from '../../../const/colors';
import {DEVICE} from '../../../const/devices';
import {MainCenterContainer} from '../../../components/Blocks';

export const Container = styled(MainCenterContainer)`
  background-color: ${COLORS.pinkLight};
  padding: 1.5rem;

  .needaboost-btn{
	background: #c9560a;
	color: white;
	padding: 8px 22px;
	border-radius: 40px;
	font-weight: 900;
	margin: auto 150px auto 0;
	text-decoration: none;
	width: 162px;
	height: 40px;
	display: flex;
    align-items: center;
    justify-content: center;
	transition: all 0.3s;

	&:hover{
		background: #dd6d22;
		color: black;
		transition: all 0.3s;
	}

	@media(max-width:800px){
		margin: 0;
	}
	
}
`;

export const Image = styled.div`
	position: absolute;
	top: -75px;
	right: -133px;
  border: 6px solid ${COLORS.white};
  border-radius: 25px;
  padding: 5px;
  margin: 1rem 10% 1rem 80%;
  width: fit-content;

  @media(max-width:1025px){
	margin: 1rem 32% 1rem 63%;
  }

  > img {
    width: 120px;
    max-height: 120px;
    object-fit: cover;
    border-radius: 20px;
	@media(max-width:465px){
		width: 80px;
		max-height: 80px;
	  }
  }
`;

export const Layout = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin-bottom: 1rem;

	@media ${DEVICE.tablet} {
    flex-direction: column;
		>div {
			margin-top: 0.5rem;
			font-size: 20px;
			> span {
				font-size: 26px;
			}
		}
	}
	
	> div {
		border-radius: 20px;
		padding: 1rem;
		font-size: 24px;
		text-align: center;
		
		&.light-orange {
			color: ${COLORS.orange};
			background: ${COLORS.grey};
		}
		&.orange {
			background: ${COLORS.orange};
			color: ${COLORS.white};
		}
		> span {
			font-size: 32px;
			font-weight: bold;
		}
	}
`;
