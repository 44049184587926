export const pageNamesUrl = [
	'intro',
	'general_info',
	'section_a_microbiome_results',
	'section_a',
	'section_a_alpha_diversity',
	'section_a_evenness',
	'section_a_richness',
	'section_a_nutritional_results',
	'section_a_protein_score',
	'section_a_fat_score',
	'section_a_carb_fiber_score',
	'section_a_chondroitin_score',
	'section_b',
	'section_b_food_recommendations',
	'section_d',
	'section_d_phyla_breakdown',
	'section_d_top_abundance',
	'section_d_book_consultation',
	'final',
	'picked_for',
];
