import styled from 'styled-components';
import {COLORS} from '../../../const/colors';
import {MainContainer} from '../../../components/Blocks';
import {DEVICE} from '../../../const/devices';

export const Container = styled(MainContainer)`
  background-color: ${COLORS.beige};
  padding: 1rem 1.5rem;

  img.run-dog-1 {
    max-width: 200px !important;
    position: absolute;
    right: -10px;
    margin-top: 1rem;
  }

  img.run-dog-2 {
    max-width: 250px !important;
    position: absolute;
		left: 25%;
    margin-top: 2rem;
  }
`;

export const Image = styled.div`
position: absolute;
top: 0;
  border: 6px solid ${COLORS.white};
  border-radius: 25px;
  padding: 5px;
  margin: 1rem 10% 1rem 80%;
  width: fit-content;

  @media(max-width:1043px){
    margin: 1rem 36% 1rem 74%;
  }
  @media(max-width:782px){
    margin: 1rem 10% 1rem 67%;
  }
  @media(max-width:503px){
    margin: 1rem 10% 1rem 54%;
  }

  > img {
    width: 150px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 20px;

    @media(max-width:1546px){
      width: 120px;
      max-height: 120px;
    }
    @media(max-width:1312px){
      width: 100px;
      max-height: 100px;
    }
  }
`;

export const BrownBlock = styled.div`
  background-color: ${COLORS.beige};
  padding: 1rem 1.5rem;
`;

export const Layout = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0.5rem 1rem;
	
	img {
		width: 70px;
		align-self: center;
	}
	
	.lay-block {
		font-size: 20px;
		margin-bottom: 0.3rem;
		margin-left: 1rem;
		
		> * {
			display: block;
		}
	}
	
		@media ${DEVICE.mobile} {
      &.col-on-mobile {
        padding: 0.5rem 0rem;
		}
	}
`;
