import React, {FunctionComponent} from 'react';
import {AvgLabel, BackgroundContainer, Container, Images, LabelsContainer, MainLabel} from './LinearGraph.styles';
import {LinearGraphProps} from './index';
import {COLORS} from '../../const/colors';

function LinearGraph({
  dogName,
  value,
  values,
  avgValue,
  images,
  additionalMark = '',
  noLabels,
  noLabelsMobile,
  ...rest
}: LinearGraphProps) {
  if (!values) {
    values = [
      {value: 0, label: 'Poor'},
      {value: 5, label: 'Ok'},
      {value: 10, label: 'Good'},
    ];
  }
  const maxValue = Math.max(...values.map((v) => v.value));
  const colors =
    values.length === 3 ?
      [COLORS.pink, COLORS.yellowDark, COLORS.green] :
      values.length === 4 ?
      [COLORS.green, COLORS.yellowLight, COLORS.yellowDark, COLORS.pink] :
      [COLORS.pink, COLORS.yellowDark, COLORS.green, COLORS.yellowDark, COLORS.pink];
  return (
    <Container {...rest}>
      {avgValue && (
        <AvgLabel value={(avgValue / maxValue) * 100}>
          <div className={'mainBlock'}>
            Average: <br />
            <span>
              {avgValue}
              {additionalMark}
            </span>
          </div>
          <div className={'arrow'}></div>
        </AvgLabel>
      )}
      {images && (
        <Images>
          {images.map((im) => (
            <span key={im}>
              <img src={im} />
            </span>
          ))}
        </Images>
      )}
      <BackgroundContainer colors={colors}>
        <MainLabel
          value={
            (value / maxValue) * 100 > 100 ? 100 : (value / maxValue) * 100
          }
          className={value === 'N/A' ? 'novalue' : 'lable'}
        >
          {dogName && <div>{dogName}:</div>}
          <div>
            {value}
            {additionalMark}
          </div>
        </MainLabel>
      </BackgroundContainer>
      <LabelsContainer noLabelsMobile={noLabelsMobile}>
        {!noLabels &&
          values.map((val, pos) => (
            <div className="values-graph" key={val.label}>
              <span style={{backgroundColor: colors[pos]}}>{val.label}</span>
              {val.subLabel && (
                <span style={{color: colors[pos]}}>
                  <br />
                  {val.subLabel}
                </span>
              )}
            </div>
          ))}
      </LabelsContainer>
    </Container>
  );
}

export default LinearGraph as FunctionComponent<LinearGraphProps>;

export function getColor(
  mainValue: number,
  values: {value: number}[],
  colors?: string[]
) {
  if (!colors) {
    colors =
      values.length === 3 ?
        [COLORS.pink, COLORS.yellowDark, COLORS.green] :
        values.length === 4 ?
        [COLORS.green, COLORS.yellowLight, COLORS.yellowDark, COLORS.pink] :
        [
            COLORS.pink,
            COLORS.yellowDark,
            COLORS.green,
            COLORS.yellowDark,
            COLORS.pink,
          ];
  }

  const diff = values.map(({value}) => Math.abs(mainValue - value));
  const min = Math.min(...diff);
  return colors[diff.indexOf(min)];
}
