import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {animated} from 'react-spring';

export const FoodForPet = styled(animated.div)<{color?: string, btnColor?: string, backGroundColor?: string}>`
  width: 480px;
  border-radius: 30px;
  background: ${({color}) => color};
  padding: 10px 10px;
  display: flex;
  margin: 30px 0 0 0;

  @media (max-width: 520px) {
    flex-direction: column-reverse;
    align-items: center;
    display: flex;
  }

  img {
    width: 150px;
    object-fit: cover;
    border-radius: 30px;

    @media (max-width: 520px) {
      width: 160px;
      border-radius: 20px;
      margin-top: 10px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 19px;
    color: white;

    @media (max-width: 520px) {
      font-size: 15px;
      margin-top: 5px;
    }
  }

  .learnmore-btn {
    display: flex;
    flex-direction: column;
    align-self: center;
    background: ${({btnColor}) => btnColor};
    border-radius: 15px;
    padding: 8px 15px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    transition: all 0.3s;
    margin-top: 25px;

    &:hover {
      background: ${({backGroundColor}) => backGroundColor};
      transition: all 0.3s;
      color: black;
    }

    @media (max-width: 520px) {
      width: 150px;
      margin-bottom: 5px;
    }
  }

  .food-image:hover {
    opacity: 50%;
  }
`;

export const BottomBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px;

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      justify-content: center;
    }
  }

  .food-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #ed3560;
    margin: 0 40px;
    object-fit: cover;
    cursor: pointer;
    flex: 50%;

    @media (max-width: 1280px) {
      width: 130px;
      height: 130px;
      margin: 0 15px;
    }
    @media (max-width: 1000px) {
      width: 120px;
      height: 120px;
      margin: 0 15px;
    }
    @media (max-width: 850px) {
      margin: 10px;
    }
  }

  .hover {
    opacity: 70%;
  }

  .text-for-icon {
    position: absolute;
    top: 65px;
    left: 39px;
    color: white;
    width: 143px;
    padding: 0 5px;
    text-align: center;
    line-height: 15px;
    font-size: 24px;
    cursor: pointer;

    @media (max-width: 1580px) {
      top: 62px;
      left: 42px;
      width: 138px;
    }
    @media (max-width: 1280px) {
      width: 119px;
      top: 52px;
      left: 15px;
    }
    @media (max-width: 1000px) {
      width: 110px;
      top: 46px;
      left: 15px;
    }
    @media (max-width: 850px) {
      width: 104px;
      top: 53px;
      left: 15px;
    }
  }
}`;

export const BlueBlock = styled(animated.div)`
  background: ${COLORS.blue};
  padding: 0.75rem;
  border-radius: 20px;
  color: ${COLORS.white};
  font-size: 24px;
	margin: 0;
`;

export const BlockNameOfPage = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Bevan', cursive;
    font-weight: 400;
    color: white;
    position: absolute;
    bottom: 17px;
    left: 35px;
    font-size: 22px;

    @media(max-width:1000px){
      font-size: 15px;
    }
    @media(max-width:750px){
      display: none;
    }
  img{
    width: 37px;
    margin: 0 10px 0 0;
    @media(max-width:1000px){
      width: 20px;
    }
    @media(max-width:750px){
      display: none;
    }
  }
`;

export const HeaderBlock = styled(animated.div)<{shadowColor?: string, color?: string}>`
  background: ${({color}) => color};
  padding: 1rem;
  color: ${COLORS.white};
  font-size: 30px;
	font-weight: 400;
	box-shadow: -14px 14px ${({shadowColor}) => shadowColor};
	display: flex;
  justify-content: center;
  align-items: center;

  .header-title{
    font-size: 57px;
    padding: 0 30px;
    text-align: center;

    @media(max-width:1067px){
      font-size: 50px;
    }
    @media(max-width:917px){
      font-size: 40px;
    }
    @media(max-width:687px){
      font-size: 25px;
    }
  }

  .section-block{
    width: 230px;
    align-items: center;

    .section{
      width: 150px;
    }

    @media(max-width:520px){
      display: none;
    }
  }

  .section-block-recomm{
    width: 230px;
    align-items: center;

    .section{
      width: 150px;
    }

    @media(max-width:670px){
      display: none !important;
    }
  }
	
	.icon {
		max-height: 70px;
		max-width: 70px;
		margin-bottom: 0.6rem;
	}
`;

export const TextBlock = styled(animated.div) <{ shadowColor?: string, color?: string }>`
  background: ${({color}) => color ? color : COLORS.brown};
  padding: 35px;
  width: 97%;
  margin: 0 auto;
  color: ${COLORS.white};
  font-size: 27px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  margin-top: 6%;

  @media (max-width: 1614px) {
    width: 94%;
  }
  @media (max-width: 970px) {
    width: 90%;
  }
  @media (max-width: 560px) {
    width: 80%;
    font-size: 25px;
  }

  p {
    margin: 25px 0;
    font-size: 20px;
  }
  
  h2 {
    font-size: 25px;
  }
`;

export const SmallTextBlock = styled(animated.div) <{ shadowColor?: string, color?: string }>`
width: 70%;
background: ${({color}) => color};
color: white;
text-align: center;
margin: 58px auto;
padding: 29px;
font-size: 34px;

@media(max-width:853px){
  font-size: 28px;
}
`;
export const Block = styled(animated.div)<{shadowColor?: string, color?: string}>`
  background: ${({color}) => color};
  padding: 10px;
  width: 89%;
  margin: 0 auto;
  color: ${COLORS.white};
  font-size: 20px;
	font-weight: 600;
	box-shadow: -14px 14px ${({shadowColor}) => shadowColor};
	display: flex;
	margin-bottom: 1.5rem;
  margin-top: 13%;
  align-items: center;

  .section-block{
    width: 160px;
    flex-direction: column;
    display: flex;
    align-items: center;
    @media(max-width:520px){
      display: none;
    }
    .icon {
      max-height: 70px;
      max-width: 70px;
      margin-bottom: 0.6rem;

      @media(max-width:1030px){
        max-height: 50px;
      max-width: 50px;
      }
    }
  }

  .header-title{
    width: 100%;
    text-align: center;
    font-size: 67px;
    padding: 0 30px;
  
    @media(max-width: 1780px){
      font-size: 55px;
    }
    @media(max-width: 1330px){
      font-size: 41px;
    }
    @media(max-width: 763px){
      font-size: 35px;
    }
  }
`;
export const TealRectBlock = styled(animated.div)`
  background: ${COLORS.teal};
  padding: 0.5rem;
  color: ${COLORS.white};
  font-size: 18px;
	margin: 0;
`;

export const WhiteBlock = styled(animated.div)`
  background: ${COLORS.white};
  padding: 10px 10px 15px 15px;
  //padding: 1rem 1rem 5rem 1rem;
  border-radius: 20px;
  color: ${COLORS.blue};
  font-size: 20px;
  margin: 0;
`;


export const YellowBlock = styled(animated.div)`
  background: ${COLORS.yellowLight};
  padding: 1rem;
  border-radius: 20px;
  color: ${COLORS.pink};
  font-size: 20px;
  margin: 0;
`;

export const MainContainer = styled(animated.div)`
  padding: 0 1.5rem 0.5rem;
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
`;

export const MainCenterContainer = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    max-height: 90vh;
  }
`;
