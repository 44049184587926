import React from 'react';
import {Container, Image, Layout, Table, Text} from './GeneralInfoPage.styles';
import dogsImage from '../../assets/img/dogs-running.png';
import DogImage1 from '../../assets/img/Odie Run.png';
import DogImage2 from '../../assets/img/Judy Running.png';
import DogImage3 from '../../assets/img/Dash Run.png';
import DogImage4 from '../../assets/img/Cookie Run.png';
import DogImage5 from '../../assets/img/Colonal run.png';
import DogImage6 from '../../assets/img/Priscilla Running.png';
import {formatDate} from '../../utils/dateFormater';
import {PageProps} from '../../dto/PageType';
import {useFadeInTop} from '../../hooks/animation';

function GeneralInfoPage({userInfo, ...rest}: PageProps) {
  const effect1 = useFadeInTop();
  return (
    <Container {...rest}>
      <Layout className={'mobile-no-layout'}>
        <div>
          <h1 style={{textAlign: 'center'}}>Hi {userInfo.dog_name}!</h1>
          <span className="greetings">Nice to meet you :)</span>
          {userInfo.dog_image && (
            <Image>
              <img src={userInfo.dog_image} />
            </Image>
          )}
          {!userInfo.dog_image && userInfo.dog_id && (
            <Image>
              {userInfo.dog_id === 1 && <img src={DogImage1} />}
              {userInfo.dog_id === 2 && <img src={DogImage2} />}
              {userInfo.dog_id === 3 && <img src={DogImage3} />}
              {userInfo.dog_id === 4 && <img src={DogImage4} />}
              {userInfo.dog_id === 5 && <img src={DogImage5} />}
              {userInfo.dog_id === 6 && <img src={DogImage6} />}
            </Image>
          )}
        </div>
        <div className="body-text">
          <Text>
            We&#39;ve crunched the numbers and prepared this tailored report just for you and {userInfo.dog_name}.
            We have a doggy database made up of all different breeds, sizes, and ages.
            Every poo sample we process adds to the database and allows us to continuously identify
            solutions to increase the healthspan of dogs worldwide - thank you for your contribution!
          </Text>
          <Text>
            Based on our findings you gain valuable insights and can tweak {userInfo.dog_name}’s daily routine/diet based on evidence rather than guesswork!
             Don’t forget to discuss the report with our team over your digital consultation - <a href="https://calendly.com/hello-treat/1-on-1-with-the-treat-team"><b>book here!</b></a>
          </Text>
        </div>
      </Layout>
      <Table style={{...effect1}}>
        <div>
          <h1>ABOUT YOU</h1>
          <Layout>
            <table>
              <tr>
                <td className="subtitle">Age</td>
                <td className="value">{userInfo.dog_age} years</td>
              </tr>
              <tr>
                <td className="subtitle">Breed</td>
                <td className="value">{userInfo.breed}</td>
              </tr>
              <tr>
                <td className="subtitle">Gender</td>
                <td className="value">{userInfo.dog_gender || '-'}</td>
              </tr>
            </table>
          </Layout>
        </div>
        <div>
          <h1>THIS REPORT</h1>

          <Layout>
            <table>
              <tr>
                <td className="subtitle">Report Date</td>
                <td className="value">{formatDate(userInfo.created_at)}</td>
              </tr>
              <tr>
                <td className="subtitle">Sample Number</td>
                <td className="value">{userInfo.sample_number}</td>
              </tr>
            </table>
          </Layout>
        </div>
      </Table>
      <img src={dogsImage} className={'bottomImage'} />
    </Container>
  );
}

export default React.memo(GeneralInfoPage);
