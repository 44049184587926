import React, {FunctionComponent} from 'react';
import {BlobProps} from './index';
import blob1 from '../../assets/img/blobs/blob-w1.svg';
import blob2 from '../../assets/img/blobs/blob-w2.svg';
import blob3 from '../../assets/img/blobs/blob-w3.svg';
import styled from 'styled-components';

const Block = styled.div`
	height: fit-content;
	overflow: hidden;
	z-index: 0;
	
	img{
    z-index: 0;
		position: absolute;
		max-height: 90%;
		top: 1.5rem;
		display: block;
		width: 100%;
	}
`;

function Blob({variant, ...rest}: BlobProps) {
	return (
		<Block>
			{variant === 1 && (
				<img src={blob1}/>
			)}
			{variant === 2 && (
				<img src={blob2}/>
			)}
			{variant === 3 && (
				<img src={blob3}/>
			)}
		</Block>
	);
}

export default Blob as FunctionComponent<BlobProps>;
