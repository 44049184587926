import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {animated} from 'react-spring';
import {DEVICE} from '../../const/devices';

export const Container = styled.div<{revertPosition?: boolean, colDoggoOnMobile?: boolean}>`
	margin-top: 1rem;
	display: flex;
	flex-direction: ${({revertPosition}) => revertPosition ? 'row-reverse' : 'row'};
	justify-content: space-around;
	position: relative;
	
	> div {
		padding: 1rem 1.5rem;
	}
	.img-cont {
		align-self: self-end;
		padding-bottom: 0 !important;
	}
	img {
		object-fit: cover;
		object-position: top;
		max-width: 500px;
		transform: ${({revertPosition}) => revertPosition ? 'scaleX(-1)' : 'none'};
		
	}
	
	@media ${DEVICE.mobile} {
		flex-direction: ${({colDoggoOnMobile, revertPosition}) => colDoggoOnMobile && revertPosition ? 'column-reverse' : colDoggoOnMobile ? 'column' : revertPosition ? 'row-reverse' : 'row'}
	}
`;

export const Header = styled(animated.h1)`
	font-size: 62px;
  	line-height: 56px;
	color: ${COLORS.pink};
  	margin-bottom: -2rem;
	position: relative;
	z-index: 3;

	
	
	text-shadow: -7px -7px 0px ${COLORS.white},
  7px 7px 0 ${COLORS.white},
  -7px 7px 0 ${COLORS.white},
    7px -7px 0 ${COLORS.white};
`;

export const Block = styled(animated.div)`
  background: ${COLORS.white};
  border-radius: 30px;
  padding: 15px 15px 15px 15px;
  color: ${COLORS.blue};
  font-size: 20px;
  z-index: 2;
`;
