import React, {FunctionComponent, useState} from 'react';
import {Container, Loader, PaginationItem, Popover, Progress, ProgressBack} from './NavigationFooter.styles';
import {BlockNameOfPage} from '../Blocks';
// @ts-ignore
import LoaderImg from '../../assets/img/loader.gif';
import {NavigatorFooterProps} from './index';
import {COLORS} from '../../const/colors';
import BloodTestIcon from '../../assets/img/icons/blood-test.png';
import PetfoodIcon from '../../assets/img/icons/pet-food.png';
import EvenessImage from '../../assets/img/icons/Evenness Score.svg';
import RichnessImage from '../../assets/img/icons/Richness Score.svg';

function NavigatorFooter({
                           value,
                           onSet,
                           maxValue,
                           error,
                           userInfo,
                           loading,
                           color = COLORS.blue,
                           isDisabledRecommendationPage,
                           ...rest
                         }: NavigatorFooterProps) {
  const NavigatorColors = [
    COLORS.blue, // 1
    COLORS.blue, // 2
    COLORS.brown, // 3
    COLORS.brown, // 4
    COLORS.brown, // 5
    COLORS.brown, // 6
    COLORS.brown, // 7
    COLORS.brown, // 8
    COLORS.brown, // 9
    COLORS.brown, // 10
    COLORS.brown, // 11
    COLORS.brown, // 12
    isDisabledRecommendationPage ? COLORS.lightblue : COLORS.mainPink, // 13
    isDisabledRecommendationPage ? COLORS.lightblue : COLORS.mainPink, // 14
    COLORS.green, // 15
    COLORS.green, // 16
    COLORS.green, // 17
    COLORS.green, // 18
    COLORS.green, // 19
  ];

  interface PageTitle {
    name: string;
    page: number;
    icon: string;
  }

  const additionalTitles: PageTitle[] = [
    {page: 1, name: '', icon: ''}, // 1
    {page: 2, name: '', icon: ''}, // 2
    {page: 3, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 4
    {page: 4, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 5
    {page: 5, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 6
    {page: 6, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 7
    {page: 7, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 8
    {page: 8, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 9
    {page: 9, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 10
    {page: 10, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 11
    {page: 11, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 12
    {page: 12, name: 'Section A: Microbiome Results', icon: BloodTestIcon}, // 12
    isDisabledRecommendationPage ?
      {page: 13, name: '', icon: ''} :
      {page: 13, name: 'Section B:  Food Recommendations', icon: PetfoodIcon}, // 13
    isDisabledRecommendationPage ?
      {page: 14, name: 'Section D:  Extras – More data', icon: ''} :
      {page: 14, name: 'Section B:  Food Recommendations', icon: PetfoodIcon}, // 14
    {page: 15, name: 'Section D:  Extras – More data', icon: RichnessImage},
    {page: 16, name: 'Section D:  Extras – More data', icon: RichnessImage},
    {page: 17, name: 'Section D:  Extras – More data', icon: RichnessImage},
    isDisabledRecommendationPage ?
      {page: 18, name: '', icon: ''} :
      {page: 18, name: 'Section D:  Extras – More data', icon: RichnessImage},
    isDisabledRecommendationPage ?
      {page: 19, name: '', icon: ''} :
      {page: 19, name: 'Section D:  Extras – More data', icon: RichnessImage}
  ];

  const Pages = isDisabledRecommendationPage ?
    [
      {name: 'Introduction', page: '', requestName: 'hiya'},
      {name: 'Welcome', page: '1', requestName: 'hiya'},
      {name: 'About You', page: '2', requestName: 'about_you'},
      {name: 'none', page: '', requestName: 'about_you'},
      // {name: 'Your Poop Sample', page: '3', requestName: 'microbiome'},
      {name: 'none', page: '', requestName: 'about_you'},
      {
        name: `Part 1: ${userInfo?.dog_name}’s Microbiome`,
        page: '3',
        requestName: 'diet_nutrition',
      },
      {name: 'Alpha Diversity', page: '4', requestName: 'alpha_diversity'},
      {name: 'Evenness', page: '5', requestName: 'evenness'},
      {name: 'Richness', page: '6', requestName: 'richness'},
      {name: 'none', page: '', requestName: 'about_you'},
      {
        name: 'Part 2: Nutritional Pathways',
        page: '7',
        requestName: 'top_proteins',
      },
      {name: 'Protein Score', page: '8', requestName: 'treats_snacks'},
      {name: 'Fat Score', page: '9', requestName: 'supplements'},
      {
        name: 'Carb & Fiber Score',
        page: '10',
        requestName: 'exercise_lifestyle',
      },
      {
        name: 'Chondroitin Score',
        page: '11',
        requestName: 'wellbeing_scores',
      },
      {
        name: 'Gut Shield Score',
        page: '12',
        requestName: 'wellbeing_scores',
      },
      {name: 'none', page: '', requestName: 'about_you'},
      {name: `THE EXTRAS:`, page: '', requestName: 'behaviour'},
      {name: 'none', page: '', requestName: 'about_you'},
      {
        name: 'Section D – More Microbiome Data',
        page: '',
        requestName: 'fat_score',
      },
      {
        name: 'Extra info on your dog’s gut',
        page: '13',
        requestName: 'carbohydrate_score',
      },
      {name: 'Phyla Breakdown', page: '14', requestName: 'chondroitin_score'},
      {name: 'Genus Breakdown', page: '15', requestName: 'chondroitin_score'},
      {name: 'none', page: '', requestName: 'about_you'},
      {name: 'Book Your Consultation', page: '16', requestName: 'book_consultation'},
      {name: 'Disclaimer', page: '16', requestName: 'going_deeper'},
    ] :
    [
      {name: 'Introduction', page: '', requestName: 'hiya'},
      {name: 'Welcome', page: '1', requestName: 'hiya'},
      {name: 'About You', page: '2', requestName: 'about_you'},
      {name: 'none', page: '', requestName: 'about_you'},
      {
        name: `Part 1: ${userInfo?.dog_name}’s Microbiome`,
        page: '3',
        requestName: 'diet_nutrition',
      },
      {name: 'Alpha Diversity', page: '4', requestName: 'alpha_diversity'},
      {name: 'Evenness', page: '5', requestName: 'evenness'},
      {name: 'Richness', page: '6', requestName: 'richness'},
      {name: 'none', page: '', requestName: 'about_you'},
      {
        name: 'Part 2: Nutritional Pathways',
        page: '7',
        requestName: 'top_proteins',
      },
      {name: 'Protein Score', page: '8', requestName: 'treats_snacks'},
      {name: 'Fat Score', page: '9', requestName: 'supplements'},
      {
        name: 'Carb & Fiber Score',
        page: '10',
        requestName: 'exercise_lifestyle',
      },
      {
        name: 'Chondroitin Score',
        page: '11',
        requestName: 'wellbeing_scores',
      },
      {
        name: 'Gut Shield Score',
        page: '12',
        requestName: 'wellbeing_scores',
      },
      {name: 'none', page: '', requestName: 'about_you'},
      {
        name: 'Section B - Food Recommendations',
        page: '',
        requestName: 'wellbeing_scores',
      },
      {name: 'What should I feed?', page: '13', requestName: 'weight'},
      {
        name: `${userInfo?.dog_name}’s recommendations`,
        page: '14',
        requestName: 'behaviour',
      },
      {name: 'none', page: '', requestName: 'about_you'},
      {
        name: 'Section D – More Microbiome Data',
        page: '',
        requestName: 'fat_score',
      },
      {
        name: 'Extra info on your dog’s gut',
        page: '15',
        requestName: 'carbohydrate_score',
      },
      {name: 'Phyla Breakdown', page: '16', requestName: 'chondroitin_score'},
      {name: 'Genus Breakdown', page: '17', requestName: 'chondroitin_score'},
      {name: 'none', page: '', requestName: 'about_you'},
      {name: 'Book Your Consultation', page: '18', requestName: 'book_consultation'},
      {name: 'Disclaimer', page: '19', requestName: 'going_deeper'},
    ];

  const reserveConsultationFunc = (e: any) => {
    e.stopPropagation();
  };

  const [openContext, setOpenContext] = useState(false);
  return (
    <Container color={NavigatorColors[value - 1]} {...rest}>
      <Progress value={value} maxValue={maxValue}/>
      <ProgressBack/>
      {loading ? (
        <Loader src={LoaderImg}/>
      ) : (
        <>
          <PaginationItem
            disabled={value === 1 || !!error}
            onClick={() => {
              value !== 1 && !error ? onSet(value - 1) : {},
                Pages[value - 2];
            }}
            className="arrow left"
          >
            <span aria-hidden="true">&laquo;</span>
          </PaginationItem>
          <PaginationItem
            onClick={() => setOpenContext(!openContext)}
            active
            className={error ? 'error' : ''}
          >
            <BlockNameOfPage>
              <img src={additionalTitles[value - 1].icon}/>
              {additionalTitles[value - 1].name}
            </BlockNameOfPage>
            {error ?
              'Unexpected error happened. Please reload page!' :
              `${value} / ${
                isDisabledRecommendationPage ? maxValue - 2 : maxValue
              }`}
            {openContext ? (
              <span
                style={{
                  transform: 'rotate(-90deg)',
                  display: 'inline-block',
                  marginLeft: '0.5rem',
                }}
              >
                &laquo;
              </span>
            ) : (
              <span
                style={{
                  transform: 'rotate(-90deg)',
                  display: 'inline-block',
                  marginLeft: '0.5rem',
                }}
              >
                &raquo;
              </span>
            )}
            <a
              href="https://calendly.com/hello-treat/1-on-1-with-the-treat-team?month=2022-10"
              target="_blank"
              rel="noreferrer"
              className="reserve-consultation-button"
              onClick={(e) => reserveConsultationFunc(e)}
            >
              Reserve your consultation
            </a>
          </PaginationItem>
          <PaginationItem
            disabled={value === maxValue || !!error}
            onClick={() => {
              if (isDisabledRecommendationPage) {
                value !== maxValue - 2 && !error ? onSet(value + 1) : {};
                Pages[value];
              } else {
                value !== maxValue && !error ? onSet(value + 1) : {};
                Pages[value];
              }
            }}
            className="arrow right"
          >
            <span aria-hidden="true">&raquo;</span>
          </PaginationItem>
        </>
      )}
      <Popover open={openContext} background={NavigatorColors[value - 1]}>
        <div>
          {Pages.map((page, pos) => {
            if (page?.name === 'none') {
              return (
                <h3 key={pos} style={{opacity: '0'}}>
                  {page?.name}
                </h3>
              );
            } else {
              return (
                <h3
                  key={pos}
                  onClick={() => {
                    if (page?.page !== '') {
                      setOpenContext(false);
                      onSet(+page?.page);
                    }
                  }}
                >
                  {page?.page + ' ' + page?.name}
                </h3>
              );
            }
          })}
        </div>
      </Popover>
    </Container>
  );
}

export default NavigatorFooter as FunctionComponent<NavigatorFooterProps>;
