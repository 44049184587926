import styled from 'styled-components';
import {COLORS} from '../../../const/colors';
import {MainContainer} from '../../../components/Blocks';

export const Container = styled(MainContainer)`
  background-color: ${COLORS.beige};
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-title {
    font-size: 68px;
    color: #e6abac;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 30px 0;
    justify-content: space-between;
  }

  .card {
    display: flex;
    flex-direction: column;
    max-width: 15%;
    max-height: 45%;
    margin: 0 auto 20px;
    align-items: center;
    box-shadow: 0 10px 20px -5px rgba(230, 171, 172, 0.7);
    padding: 20px;
    border-radius: 40px;
    transition: transform 0.3s ease;
    background-color: white;
  }

  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 40px -10px rgba(230, 171, 172, 0.7);
  }

  .card-title {
    text-align: center;
    align-items: center;
    font-size: 15px;
    margin-bottom: 10px;
    height: 50px;
    font-weight: 900;
    color: #e6abac;
  }

  img {
    max-width: 60%;
    object-fit: cover;
  }

  .card-body {
    text-align: center;
    height: 150px;
  }

  .card-button {
    a {
      align-items: center;
      justify-content: center;
      background-color: #e6abac;
      color: white;
      text-decoration: none;
      margin-top: 10px;
      padding: 10px;
      border-radius: 10px;
    }
  }

  ul {
    list-style: circle;
    font-size: 15px;
    text-align: left;
    margin-left: 15px;
    color: #e6abac;
  }

  @media (max-width: 1200px) {
    .header-title {
      justify-content: flex-start;
    }

    .card {
      max-width: 35%;
    }

    .header-title {
      font-size: 52px;
    }
  }

  @media (max-width: 480px) {
    .row {
      flex-direction: column;
    }

    .card {
      max-width: 80%;
    }

    .header-title {
      font-size: 30px;
    }
  }
`;

export const WhiteBlock = styled.div`
  background-color: ${COLORS.white};
  padding: 1rem 1.5rem;
`;
