import styled from 'styled-components';
import {MainCenterContainer} from '../../../components/Blocks';
import {COLORS} from '../../../const/colors';

export const Container = styled(MainCenterContainer)`
  background-color: ${COLORS.pinkLight};

  
  .biggerText {
		font-weight: bold;
		font-size: 28px;
	}

  br:before {
    display: block;
    margin-top: 10px;
    content: "";
  }
	
	.img-cont {
		display: block;
		margin: auto;
	}
`;

export const Image = styled.div`
position: absolute;
top: 20px;
right: -138px;
z-index: 2;
  border: 6px solid ${COLORS.white};
  border-radius: 25px;
  padding: 5px;
  margin: 1rem 10% 1rem 80%;
  width: fit-content;

  @media(max-width: 1640px){
	right: -20px;
  }
  @media(max-width: 1330px){
	top: 10px;
  }
  @media(max-width: 1030px){
	top: 0px;
  }
  @media(max-width: 920px){
	top: -10px;
  }
  @media(max-width: 710px){
	top: -20px;
  }
  @media(max-width: 635px){
	display: none;
  }

  > img {
	width: 97px;
    max-height: 97px;
    object-fit: cover;
    border-radius: 20px;
	@media(max-width: 700px){
		width: 90px;
    	max-height: 90px;
	  }
	  @media(max-width: 635px){
		display: none;
	  }
  }
`;
