import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {animated} from 'react-spring';

export const Container = styled.div`
	margin-top: 1.5rem;
  padding: 0 0 30px 0;
`;

export const Header = styled(animated.div)<{color?:string}>`
  background: ${({color}) => color ? color : COLORS.pink};
  width: fit-content;
  font-size: 32px;
  font-family: 'Bevan', cursive;
  font-weight: 400;
  color: ${COLORS.white};
  margin-left: 1.5rem;
  margin-bottom: -1.5rem;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-flow: nowrap !important;
  padding: 1rem !important;

  @media(max-width:590px){
    font-size: 25px;
  }
  @media(max-width:490px){
    font-size: 20px;
  }

  img {
    width: 35px;
    align-self: center;
    margin: 0.3rem 1rem 0.3rem 0.3rem;
  }
`;

export const Block = styled(animated.div)<{color?:string}>`
	background: ${({color}) => color ? color : COLORS.white};
	padding: 35px;
	border-radius: 110px;
	font-size: 22px;
	color: ${COLORS.blue};
	z-index: 1;
	position: relative;
  padding: 35px 35px 70px 35px;

  .block-with-header-body{
  }
`;
