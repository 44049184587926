import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {DEVICE} from '../../const/devices';
import {animated} from 'react-spring';

export const Container = styled.div`
	margin: 1.5rem 2rem 0.5rem;
  @media ${DEVICE.tablet} {
    margin: 1.5rem 0 0.5rem;
		.label {
      margin-left: 0;
		}
  }
	
	.label {
		font-size: 24px;
		font-weight: bolder;
		margin-bottom: 0.3rem;
		margin-left: 1rem;
	}
`;

export const Layout = styled(animated.div)`
	display: flex;
	flex-direction: row;
	justify-content: start;
	font-size: 24px;
	font-weight: bolder;
`;

export const Progress = styled(animated.div)<{ color?: string, width: number }>`
	border-radius: 20px;
  background: ${({color}) => color ? color : COLORS.orange};
  width: ${({width}) => width}%;
	color: ${COLORS.white};
	padding: 0.5rem 1rem;
	box-sizing: border-box;
	height: 2.5rem;
  z-index: 2;
	font-size: 22px;
	text-align: right;
`;

export const Background = styled.div`
	border-radius: 20px;
	background: ${COLORS.pinkLight};
	height: 2.5rem;
	flex-grow: 1;
	margin-left: -2rem;
  z-index: 1;
`;
