import React, {FunctionComponent} from 'react';
import {Container} from './PhylaBreakdown.styles';
import {COLORS} from '../../../const/colors';
import MicroIcon from '../../../assets/img/icons/height-icon.png';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import {PageProps} from '../../../dto/PageType';

function PhylaBreakdown({userInfo, ...rest}: PageProps) {
  return (
    <Container {...rest}>
      <div>
        <BlockWithHeader
          header={'PHYLA BREAKDOWN'}
          headerColor={COLORS.green}
          blockColor={'white'}
          icon={MicroIcon}
        >
          <div>
            <p>
              A phylum is a group of bacteria (plural “phyla”).
              Each phylum is closely linked to what makes up your dog’s diet.
              For example, bacteria in the <i>Firmicutes</i> phylum are linked to supporting the breakdown of carbohydrates
              during digestion.
            </p><br />
            <p>
              Here, we have a comparison of the phyla in {userInfo.dog_name}'s gut versus the phyla typically
              found in healthy dogs.
            </p><br />
            <p>
              Imagine how much the distribution of phyla can change with dietary changes…
            </p>
          </div>
          <div
            className="phyla_images"
            style={{display: 'flex', flexWrap: 'wrap'}}
          >
            {userInfo.phyla_chart_legend_link && (
              <img
                className={'imageChart'}
                src={userInfo.phyla_chart_legend_link}
              />
            )}
            {userInfo.phyla_chart_link_img && (
              <img className={'imageChart'} src={userInfo.phyla_chart_link_img} />
            )}
            {userInfo.phyla_chart_avg_link && (
              <img
                className={'imageChart'}
                src={userInfo.phyla_chart_avg_link}
              />
            )}
          </div>
        </BlockWithHeader>
      </div>
    </Container>
  );
}

export default React.memo(PhylaBreakdown) as FunctionComponent<PageProps>;
