import React, {FunctionComponent} from 'react';
import {Container, Image} from './FoodRecommendationsPage.styles';
import {HeaderBlock, TextBlock} from '../../../components/Blocks';
import {PageProps} from '../../../dto/PageType';
import PetfoodIcon from '../../../assets/img/icons/pet-food.png';
import {COLORS} from '../../../const/colors';

function FoodRecommendationsPage({userInfo, ...rest}: PageProps) {
  return (
    <Container {...rest}>
      <HeaderBlock color={COLORS.mainPink} shadowColor={COLORS.darkPurple}>
        <div
          className="section-block-recomm"
          style={{
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img className={'icon hide-on-mobile'} src={PetfoodIcon} />
          <span className="section">Section B</span>
        </div>
        <h1 className="header-title">Personalised Food Recommendations</h1>
      </HeaderBlock>
      {userInfo.dog_image && (
        <Image>
          <img src={userInfo.dog_image} />
        </Image>
      )}
      <TextBlock color={COLORS.mainPink}>
        <div>
          <p>
              It’s hard to choose food for our dogs. We know.
              Fussiness, allergies, and gut issues are all massive problems for us as pet-parents.
              They can’t even tell us what they like!
          </p>
          <p>
              Here, based on {userInfo.dog_name}'s microbiome scores, our algorithm has curated a tailored list
              of products from our database of over 3,000 commercial foods.
              Of course, this is not an exhaustive list, but it is a great place to start if you're overwhelmed.
              Feel free to discuss further in your consultation.
          </p>
        </div>
      </TextBlock>
    </Container>
  );
}

export default React.memo(FoodRecommendationsPage) as FunctionComponent<PageProps>;
