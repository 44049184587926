import React, {FunctionComponent, useState} from 'react';
import {Container, Image} from '../../ScoresGraphs/ScoreGraphs.styles';
import BlockWithHeader from '../../../components/BlockWithHeader/BlockWithHeader';
import BaseDietIcon from '../../../assets/img/icons/Base Diet.svg';
import {PageProps} from '../../../dto/PageType';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import FluffyFullImage from '../../../assets/img/images/FluffyFull.webp';
import {COLORS} from '../../../const/colors';
import {BottomBlock, FoodForPet} from '../../../components/Blocks';

function ProteinPage({userInfo, ...rest}: PageProps) {
  const values = [
    {value: 0, label: 'Inefficient'},
    {value: 5, label: 'Ok'},
    {value: 10, label: 'Efficient'},
  ];
  const [idImageToShow, setIsImageToShow] = useState<string>();

  return (
    <Container {...rest}>
      {userInfo.dog_image && (
        <Image>
          <img src={userInfo.dog_image} className="hide-on-mobile"/>
        </Image>
      )}
      <div>
        <BlockWithHeader
          header={'PROTEIN SCORE'}
          icon={BaseDietIcon}
          headerColor={COLORS.darkBrown}
        >
          {userInfo.protein_score ? (
            <>
              <LinearGraph
                value={userInfo.protein_score}
                dogName={userInfo.dog_name}
                values={values}
              />
              {userInfo.protein_score > 6 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}’s gut is{' '}
                    <span style={{color: '#95b660'}}>efficient</span> at
                    breaking down protein!
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s sample contains{' '}
                    <span style={{color: '#95b660'}}>a lot of useful bacteria</span>{' '}
                    that help break down and digest protein.
                    This means that they absorb a lot of protein from their diet with
                    little effort!
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Your dog’s gut breaks down protein into amino acids that are pivotal
                    for growth and repair.
                    Efficient protein degradation prevents poor coat condition,
                    weight loss, and growth issues.{' '}
                    <span style={{color: '#95b660'}}>However,</span>{' '}
                    it is important to note that high protein levels have also been
                    linked to anxiety, aggression, and weight gain.
                    If {userInfo.dog_name} struggles with any of these issues,
                    consider feeding lower in protein meat sources.
                    Feel free to ask about this in your consultation!
                  </div>
                </>
              )}
              {userInfo.protein_score <= 6 && userInfo.protein_score >= 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut is{' '}
                    <span style={{color: '#ecb33d'}}>ok</span> at breaking down
                    protein
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s sample contains{' '}
                    <span style={{color: '#ecb33d'}}>some</span>{' '}
                    bacteria that help break down and digest protein.
                    This means that although {userInfo.dog_gender === 'FEMALE' ? 'she' : 'he'} is probably absorbing sufficient protein
                    from his diet, {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} gut has to work a little harder than others to do so.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    When your dog eats protein, their gut breaks it down into
                    different amino acids. These amino acids are pivotal for growth and repair.
                    As {userInfo.dog_name} is ok at breaking down protein,
                    watch out for signs of amino acid deficiencies: poor coat condition,
                    weight loss and growth issues.
                    Consider feeding {userInfo.dog_name} foods that contain highly
                    digestible, usable protein. Ask about higher protein meats
                    in your consultation!
                  </div>
                </>
              )}
              {userInfo.protein_score < 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut is{' '}
                    <span style={{color: '#ed3560'}}>inefficient </span> at
                    breaking down protein
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    {userInfo.dog_name}'s sample contains{' '}
                    <span style={{color: '#ed3560'}}>very few</span> bacteria that
                    help them break down and digest protein.
                    This means that even though {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet may already contain protein,
                    {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} gut has to use a relatively high amount of effort to break down
                    this protein. {userInfo.dog_name} may not be absorbing enough protein
                    for optimal health.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    When your dog eats protein, their gut breaks it down into
                    different amino acids. These amino acids are pivotal for growth and
                    repair. As {userInfo.dog_name} is inefficient at breaking down protein,
                    the resulting lack of amino acids can cause poor coat condition,
                    weight loss and growth issues.
                    Consider feeding {userInfo.dog_name} foods that contain highly digestible,
                    usable protein. Ask about higher protein meats in your consultation!
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <LinearGraph
                value={0}
                dogName={userInfo.dog_name}
                values={values}
              />
              <>
                <div style={{margin: '2rem 0'}}>
                  {userInfo.dog_name}’s gut is{' '}
                  <span style={{color: '#ed3560'}}>inefficient </span> at
                  breaking down protein
                </div>
                <div className="subtitle">What does this mean?</div>
                <div style={{marginBottom: '2rem'}}>
                  {userInfo.dog_name}’s gut has very few bacteria that
                  help {userInfo.dog_gender === 'FEMALE' ? 'her' : 'him'} break down and digest protein.
                  This means that {userInfo.dog_gender === 'FEMALE' ? 'she' : 'he'} gets protein
                  from {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} diet with significant
                  effort. {userInfo.dog_name} may not be absorbing enough protein
                  for {userInfo.dog_gender === 'FEMALE' ? 'her' : 'his'} health.{' '}
                </div>
                <div className="subtitle">Why should I care?</div>
                <div>
                  Low protein levels cause poor coat condition, weight loss
                  and growth issues. Consider including higher protein meats
                  in {userInfo.dog_name}’s diet to prevent this.{' '}
                </div>
              </>
            </>
          )}
          <BottomBlock>
            <div className="container">
              {userInfo?.protein_food_recommendation?.length ? (
                <>
                  <div className="subtitle">What to feed:</div>
                  {userInfo?.protein_food_recommendation.map((elem: any) => (
                    <div
                      key={elem.id}
                      style={{position: 'relative'}}
                      onMouseOver={(e) => (
                        e.stopPropagation(), setIsImageToShow(elem.id)
                      )}
                      onMouseLeave={() => setIsImageToShow('')}
                    >
                      <a href={elem.link_url} target="_blank" rel="noreferrer">
                        <img
                          src={elem.image_url}
                          className={`food-image ${
                            idImageToShow === elem.id && 'hover'
                          }`}
                        />
                        {idImageToShow === elem.id && (
                          <div className="text-for-icon">{elem.name}</div>
                        )}
                      </a>
                    </div>
                  ))}
                </>
              ) : (
                ''
              )}
            </div>
            {userInfo.protein_score && userInfo?.show_supplements ? (
              userInfo.protein_score < 7 ? (
                <FoodForPet
                  color="#e5abac"
                  btnColor="#832f56"
                  backGroundColor="#a23f6d"
                >
                  <div className="text">
                    Poor coat condition is a symptom of Protein deficiency.
                    <br/> <br/>
                    <span>
                      FLUFFY+FULL can give {userInfo.dog_name} the support they
                      need
                    </span>
                    <a
                      href="https://www.treattherapeutics.com/products/fluffy-full?utm_source=report&utm_medium=button&utm_campaign=report-rec-fluffy&utm_id=report-rec-fluffy"
                      target="_blank"
                      rel="noreferrer"
                      className="learnmore-btn"
                    >
                      Learn more
                    </a>
                  </div>
                  <img src={FluffyFullImage}/>
                </FoodForPet>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </BottomBlock>
        </BlockWithHeader>
      </div>
    </Container>
  );
}

export default React.memo(ProteinPage) as FunctionComponent<PageProps>;
