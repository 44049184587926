import styled from 'styled-components';
import {MainCenterContainer} from '../../../components/Blocks';
import {COLORS} from '../../../const/colors';

export const Container = styled(MainCenterContainer)`
  background-color: ${COLORS.pinkLight};
	
  .biggerText {
		font-weight: bold;
		font-size: 28px;
	}

  br:before {
    display: block;
    margin-top: 10px;
    content: "";
  }
	
	.img-cont {
		display: block;
		margin: auto;
	}
`;

export const Image = styled.div`
	position: absolute;
	top: 0px;
	z-index: 2;
	border: 6px solid ${COLORS.white};
	border-radius: 25px;
	padding: 5px;
	margin: 1rem 10% 1rem 80%;
	width: fit-content;

  @media(max-width: 1380px){
	margin: 1rem 10% 1rem 64%;
  }
  @media(max-width: 683px){
	margin: 1rem 10% 1rem 71%;
  }
  @media(max-width: 610px){
	display: none;
  }

  > img {
    width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 20px;
	@media(max-width: 683px){
		width: 100px;
    	max-height: 100px;
	  }
	  @media(max-width: 610px){
		display: none;
	  }
  }
`;
