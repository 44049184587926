import styled from 'styled-components';
import {COLORS} from '../../const/colors';
import {animated} from 'react-spring';

export const Container = styled(animated.div)`
  box-sizing: border-box;
  height: 100vh;
  background-color: ${COLORS.blue};
  overflow-y: scroll;
  overflow-x: hidden;

  .header {
    margin: 20px 0 20px 0;
    font-size: 70px;
    text-align: center;
    color: white;

    @media (max-width: 420px) {
      font-size: 25px;
    }
  }
`;

export const LogoBlock = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 50px;
  margin-top: 5%;

  @media (max-width: 620px) {
    flex-direction: column;
    margin-top: 0;
  }

  img {
    max-width: 200px;
    height: auto;
    object-fit: contain;
  }

  &:first-child {
    flex: 1;
  }
`;

export const Sections = styled(animated.div)`
  
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    width: 100%;
    
    @media (max-width: 860px) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  .section {
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 400px;
    height: 120px;
    border-radius: 30px;
    padding: 30px;
    cursor: pointer;
    transition: all 0.3s;
    flex-direction: column;
    
    @media (max-width: 420px) {
      width: 200px;
      height: 60px;
    }

    &:hover {
      .text {
        transition: all 0.3s;
      }
    }

    img {
      width: 70px;
      height: 70px;
      margin-bottom: 5px;
    }

    .text {
      color: white;
      font-family: 'Bevan', cursive;
      font-weight: 400;
      font-size: 28px;
      line-height: 24px;
      
      @media (max-width: 420px) {
        font-size: 14px;
        line-height: 12px;
      }
    }
  }

  .microbiom {
    background: #dfb870;
    transition: all 0.3s;

    &:hover {
      transform: scale(1);
      background: #ecc783;
      transition: all 0.3s;
    }
  }

  .recommendation {
    background: #e6abac;
    transition: all 0.3s;

    &:hover {
      transform: scale(1);
      background: #f0bcbd;
      transition: all 0.3s;
    }
  }

  .moreData {
    background: #a2d19a;
    transition: all 0.3s;

    &:hover {
      transform: scale(1);
      background: #b6e5ae;
      transition: all 0.3s;
    }
  }
`;
