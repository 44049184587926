import React, {useEffect, useState} from 'react';
import IntroPage from './containers/IntroPage';
import GeneralInfoPage from './containers/GeneralInfoPage';
import PopulationPage from './containers/SectionA/PopulationPage';
import NavigationFooter from './components/NavigationFooter';
import MicrobiomeResults from './containers/SectionA/MicrobiomeResults';
import NutritionalPage from './containers/SectionA/NutritionalPage/NutritionalPage';
import FoodRecommendationsPage from './containers/SectionB/FoodRecommendationsPage/FoodRecommendationsPage';
import PickedFor from './containers/SectionB/PickedFor/PickedFor';
import CarbAndFiberPage from './containers/SectionA/CarbAndFiberPage/CarbAndFiberPage';
import ChondroitinPage from './containers/SectionA/ChondroitinPage/ChondroitinPage';
import AlphaDiversityGraphPage from './containers/SectionA/AlphaDiversityGraphPage';
import SectionD from './containers/SectionD/SectionD/SectionD';
import BookYourConsultationPage from './containers/SectionD/BookYourConsultationPage';
import FinalPage from './containers/FinalPage';
import {UserInfoDTO} from './dto/UserInfoDTO';
import {useParams} from 'react-router-dom';
import {useSpring} from 'react-spring';
import RichnessGraphPage from './containers/SectionA/RichnessGraphPage';
import EvennessGraphPage from './containers/SectionA/EvennessGraphPage';
import ProteinPage from './containers/SectionA/ProteinPage';
import FatPage from './containers/SectionA/FatPage';
import PhylaBreakdown from './containers/SectionD/PhylaBreakdown';
import GenusPage from './containers/SectionD/GenusPage';
import {pageNamesUrl} from './const/reportNames';
import SCFAPage from './containers/SectionA/SCFAPage';

function App() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [userInfo, setUserInfo] = useState<UserInfoDTO>();
  const [isDisabledRecommendationPage, setIsDisabledRecommendationPage] = useState<boolean>();

  const pagesNumber = 19;

  const userToken = useParams().token;
  const pageStyles = [];
  for (let i = 1; i <= pagesNumber; i++) {
    pageStyles.push(useSpring({opacity: activeTab === i ? 1 : 0}));
  }
  useEffect(() => {
    (window as any).UST?.addTag(pageNamesUrl[activeTab - 1]);
  }, [activeTab-1]);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/reports/${userToken}/review`)
      .then((res) => res.json())
      .then(
        (result) => {
          setUserInfo(result);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
  }, []);

  useEffect(() => {
    if (!userInfo) return;
    if (
      userInfo?.disabled_pages?.find(
        (elem: string) => elem === 'dietary_recommendations'
      )
    ) {
      setIsDisabledRecommendationPage(true);
    } else {
      setIsDisabledRecommendationPage(false);
    }
  }, [userInfo]);

  return (
    <>
      {userInfo ? (
        <div className="App">
          {activeTab === 1 && (
            <IntroPage
              userInfo={userInfo}
              style={pageStyles[0]}
              setActiveTab={setActiveTab}
              isDisabledRecommendationPage={isDisabledRecommendationPage}
            />
          )}
          {activeTab === 2 && (
            <GeneralInfoPage userInfo={userInfo} style={pageStyles[1]} />
          )}
          {activeTab === 3 && (
            <PopulationPage userInfo={userInfo} style={pageStyles[2]} />
          )}
          {activeTab === 4 && (
            <AlphaDiversityGraphPage
              userInfo={userInfo}
              style={pageStyles[3]}
            />
          )}
          {activeTab === 5 && (
            <EvennessGraphPage userInfo={userInfo} style={pageStyles[4]} />
          )}
          {activeTab === 6 && (
            <RichnessGraphPage userInfo={userInfo} style={pageStyles[5]} />
          )}
          {activeTab === 7 && (
            <NutritionalPage userInfo={userInfo} style={pageStyles[6]} />
          )}
          {activeTab === 8 && (
            <ProteinPage userInfo={userInfo} style={pageStyles[7]} />
          )}
          {activeTab === 9 && (
            <FatPage userInfo={userInfo} style={pageStyles[8]} />
          )}
          {activeTab === 10 && (
            <CarbAndFiberPage userInfo={userInfo} style={pageStyles[9]} />
          )}
          {activeTab === 11 && (
            <ChondroitinPage userInfo={userInfo} style={pageStyles[10]} />
          )}
          {activeTab === 12 && (
            <SCFAPage userInfo={userInfo} style={pageStyles[11]} />
          )}
          {isDisabledRecommendationPage ? (
            <>
              {activeTab === 13 && (
                <SectionD userInfo={userInfo} style={pageStyles[12]} />
              )}
              {activeTab === 14 && (
                <PhylaBreakdown userInfo={userInfo} style={pageStyles[13]} />
              )}
              {activeTab === 15 && (
                <GenusPage userInfo={userInfo} style={pageStyles[14]} />
              )}
              {activeTab === 16 && (
                <BookYourConsultationPage
                  userInfo={userInfo}
                  style={pageStyles[15]}
                />
              )}
              {activeTab === 17 && (
                <FinalPage userInfo={userInfo} style={pageStyles[16]} />
              )}
            </>
          ) : (
              <>
                {activeTab === 13 && (
                <FoodRecommendationsPage
                  userInfo={userInfo}
                  style={pageStyles[12]}
            />
              )}
              {activeTab === 14 && (
                <PickedFor userInfo={userInfo} style={pageStyles[13]} />
              )}
              {activeTab === 15 && (
                <SectionD userInfo={userInfo} style={pageStyles[14]} />
              )}
              {activeTab === 16 && (
                <PhylaBreakdown userInfo={userInfo} style={pageStyles[15]} />
                )}
                {activeTab === 17 && (
                <GenusPage userInfo={userInfo} style={pageStyles[16]} />
              )}
              {activeTab === 18 && (
                <BookYourConsultationPage
                  userInfo={userInfo}
                  style={pageStyles[17]}
                />
              )}
              {activeTab === 19 && (
                <FinalPage userInfo={userInfo} style={pageStyles[18]} />
              )}
            </>
          )}

          <NavigationFooter
            value={activeTab}
            maxValue={pagesNumber}
            userInfo={userInfo}
            onSet={setActiveTab}
            error={error}
            loading={loading}
            isDisabledRecommendationPage={isDisabledRecommendationPage}
          />
        </div>
      ) : (
        <div className="App">
          <IntroPage userInfo={userInfo} setActiveTab={setActiveTab} />
          <NavigationFooter
            value={activeTab}
            maxValue={pagesNumber}
            userInfo={userInfo}
            onSet={setActiveTab}
            error={error}
            loading={loading}
            isDisabledRecommendationPage={isDisabledRecommendationPage}
          />
        </div>
      )}
    </>
  );
}

export default App;
