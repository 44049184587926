import {useSpring} from 'react-spring';

export function useScale(from?: number, to?: number, delay?: number, duration?: number) {
	return useSpring({
		from: {
			scale: from || 0.8
		},
		to: {
			scale: to || 1
		},
		delay: delay || 100,
		config: {duration: duration || 400},
	});
}

export function useOpacity(from?: number, to?: number, delay?: number, duration?: number) {
	return useSpring({
		from: {
			opacity: from || 0
		},
		to: {
			opacity: to || 1
		},
		delay: delay || 100,
		config: {duration: duration || 400},
	});
}

export function useFadeInRight(from?: number, to?: number, delay?: number, duration?: number) {
	return useSpring({
		from: {
			opacity: 0,
			x: from || -100
		},
		to: {
			opacity: 1,
			x: to || 0
		},
		delay: delay || 100,
		config: {duration: duration || 400},
	});
}

export function useFadeInLeft(from?: number, to?: number, delay?: number, duration?: number) {
	return useSpring({
		from: {
			opacity: 0,
			x: from || 100
		},
		to: {
			opacity: 1,
			x: to || 0
		},
		delay: delay || 100,
		config: {duration: duration || 400},
	});
}

export function useFadeInTop(from?: number, to?: number, delay?: number, duration?: number) {
	return useSpring({
		from: {
			opacity: 0,
			y: from || 300
		},
		to: {
			opacity: 1,
			y: to || 0
		},
		delay: delay || 100,
		config: {duration: duration || 400},
	});
}

export function useFadeInBottom(from?: number, to?: number, delay?: number, duration?: number) {
	return useSpring({
		from: {
			opacity: 0,
			y: from || -300
		},
		to: {
			opacity: 1,
			y: to || 0
		},
		delay: delay || 100,
		config: {duration: duration || 400},
	});
}
