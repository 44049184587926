import React, {FunctionComponent} from 'react';
import {Container, Image} from './PopulationPage.styles';
import {Block, SmallTextBlock} from '../../../components/Blocks';
import MicrobionIcon from '../../../assets/img/icons/Microbiome Analysis — white.svg';
import {PageProps} from '../../../dto/PageType';
import {COLORS} from '../../../const/colors';

function PopulationPage({userInfo, ...rest}: PageProps) {
  const reserveConsultationFunc = (e: any) => {
    e.stopPropagation();
  };

  return (
    <>
      <Container {...rest}>
        {userInfo.dog_image && (
          <Image>
            <img src={userInfo.dog_image} />
          </Image>
        )}
        <Block color={COLORS.darkBrown} shadowColor={COLORS.brown}>
          <div
            className="section-block"
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img className={'icon hide-on-mobile'} src={MicrobionIcon} />
            <span className="section">Part 1</span>
          </div>
          <h1 className="header-title">Microbiome Population Results</h1>
        </Block>
        {/* <SmallTextBlock color={COLORS.darkBrown}>*/}
        {/*    We’ve crunched the numbers and prepared this tailored report just for you and {userInfo.dog_name}.*/}
        {/*    We have a doggy database made up of all different breeds, sizes, and ages.*/}
        {/*    Every poo sample we process adds to the database and allows us to continuously identify solutions*/}
        {/*    to increase the healthspan of dogs worldwide - thank you for your contribution!*/}
        {/* </SmallTextBlock>*/}
        {/* <SmallTextBlock color={COLORS.darkBrown}>*/}
        {/*    Based on our findings you gain valuable insights and can tweak {userInfo.dog_name}'s daily*/}
        {/*    routine/diet based on evidence rather than guesswork!*/}
        {/*    Don’t forget to discuss the report with our team over your complementary*/}
        {/*    digital consultation - {' '}*/}
        {/*    <a*/}
        {/*      href="https://calendly.com/hello-treat/1-on-1-with-the-treat-team?month=2022-10"*/}
        {/*      target="_blank"*/}
        {/*      rel="noreferrer"*/}
        {/*      className="reserve-consultation-button"*/}
        {/*      onClick={(e) => reserveConsultationFunc(e)}*/}
        {/*    >book here</a>.*/}
        {/* </SmallTextBlock>*/}
      </Container>
    </>
  );
}

export default React.memo(PopulationPage) as FunctionComponent<PageProps>;
