import React, {FunctionComponent} from 'react';
import {Container, ImageOverflow} from './BookYourConsultationPage.styles';
import {HeaderBlock, WhiteBlock} from '../../../components/Blocks';
import DogsImg from '../../../assets/img/images/Thank you card Base.png';
import {PageProps} from '../../../dto/PageType';
import {COLORS} from '../../../const/colors';
import {useFadeInBottom} from '../../../hooks/animation';

function BookYourConsultationPage({userInfo, ...rest}: PageProps) {
  const effect = useFadeInBottom(-100);

  // get the current month and year
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const href = userInfo.user.email === 'catherineabrown@hotmail.co.uk' ?
    `https://calendly.com/hello-treat/1-on-1-with-treat?month=${currentYear}-${currentMonth.toString().padStart(2, '0')}` :
    `https://calendly.com/hello-treat/1-on-1-with-the-treat-team?month=${currentYear}-${currentMonth.toString().padStart(2, '0')}`;

  return (
    <Container {...rest}>
      <div>
        <HeaderBlock color={COLORS.green} style={effect}>
          <h1 className="header-title">Book your consultation!</h1>
        </HeaderBlock>
        <WhiteBlock style={{marginBottom: '1rem'}}>
          <div>
            Want to get a deeper level of insight on what this all means? Book
            your 1-on-1 consultation with our team below!
          </div>
        </WhiteBlock>
        <div style={{margin: '50px 0 0 30px'}}>
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className={'button-book-now'}
          >
            Book Now
          </a>
        </div>
        <ImageOverflow className={'hide-on-mobile'} src={DogsImg} />
      </div>
    </Container>
  );
}

export default React.memo(BookYourConsultationPage) as FunctionComponent<PageProps>;
