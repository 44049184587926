import React, {FunctionComponent, useState} from 'react';
import {Container, Image} from './RichnessGraphPage.styles';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import {COLORS} from '../../../const/colors';
import BlockWithHeader from '../../../components/BlockWithHeader';
import {PageProps} from '../../../dto/PageType';
import MicrobionIcon from '../../../assets/img/icons/Microbiome Analysis — white.svg';
import Blob from '../../../components/Blob/Blob';
import {BottomBlock} from '../../../components/Blocks';

function RichnessGraphPage({userInfo, ...rest}: PageProps) {
  const [idImageToShow, setIsImageToShow] = useState<string>();
  const values = [
    {value: 0, label: 'Poor'},
    {value: 5, label: 'Ok'},
    {value: 10, label: 'Good'},
  ];

  return (
    <Container {...rest}>
      <div>
        <BlockWithHeader
          header={'RICHNESS'}
          headerColor={COLORS.brown}
          icon={MicrobionIcon}
        >
          {userInfo.dog_image && (
            <Image>
              <img src={userInfo.dog_image} />
            </Image>
          )}
          {userInfo.richness2 ? (
            <>
              <>
              <LinearGraph
              values={values}
              value={userInfo.richness2}
              dogName={userInfo.dog_name}
              additionalMark={''}
            /></>
              {userInfo.richness2 > 6 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut bacterial richness is{' '}
                    <span style={{color: '#95b660'}}>highly diverse!</span>
                  </div>
                  <br />
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    Richness measures the number of{' '}
                    <span style={{color: '#95b660'}}>different types of bacteria</span>{' '}
                    in {userInfo.dog_name}'s gut, rather than how evenly distributed
                    the bacteria are (that is measured in the evenness score we’ve
                    already discussed).
                    A high richness means that {userInfo.dog_name} has lots of
                    different types of bacteria!
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Having a high richness score helps prevent the onset of
                    chronic diseases such as allergies and digestion issues.
                    A high number of types of bacteria is necessary to break down
                    essential nutrients and support good health -{' '}
                    {userInfo.dog_name} is on the right path!
                  </div>
                </>
              )}
              {userInfo.richness2 <= 6 && userInfo.richness2 >= 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s gut bacterial richness is{' '}
                    <span style={{color: '#ecb33d'}}>moderately diverse.</span>
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    Richness measures the number of{' '}
                    <span style={{color: '#ecb33d'}}>different types of bacteria</span> in{' '}
                    {userInfo.dog_name}'s gut, rather than how evenly distributed
                    the bacteria are (that is measured in the evenness score we’ve already discussed).
                    A moderate richness indicates the presence of a decent number of bacteria that
                    can support your dog’s health, however, you can make some improvements
                    to further increase the number of bacteria and ensure total health.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Having a low richness score puts {userInfo.dog_name} at risk of
                    chronic diseases such as allergies and digestion issues.
                    They may not have the types of bacteria necessary
                    for breaking down nutrients, producing vitamins and maintaining
                    good health. Dietary variation or addition of pre AND probiotics
                    can help increase the number of good bacteria in {userInfo.dog_name}'s
                    gut long-term!
                  </div>
                </>
              )}
              {userInfo.richness2 < 4 && (
                <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}'s bacterial richness is{' '}
                    <span style={{color: '#ed3560'}}>lacking.</span>
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    Richness measures the number of{' '}
                    <span style={{color: '#ed3560'}}>different types of bacteria</span> in{' '}
                    {userInfo.dog_name}'s gut, rather than how evenly distributed
                    the bacteria are (that is measured in the evenness score we’ve
                    already discussed!). A low richness score indicates there
                    is not enough different types of bacteria to support your
                    dog’s immune system and overall health.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Having a low richness score puts {userInfo.dog_name} at risk
                    of chronic diseases such as allergies and digestion issues.
                    They may not have the right types of bacteria for breaking down
                    certain nutrients, producing vitamins and maintaining good health.
                    Dietary variation or addition of pre AND probiotics can help increase
                    the number of good bacteria in {userInfo.dog_name}'s gut long-term!
                  </div>
                </>
              )}
            </>
          ) : (
              <>
                 <LinearGraph
              values={values}
              value={0}
              avgValue={userInfo.breed_info?.average_richness}
              dogName={userInfo.dog_name}
                />
                 <>
                  <div style={{margin: '2rem 0'}}>
                    {userInfo.dog_name}’s gut bacteria population is{' '}
                    <span style={{color: '#ed3560'}}>lacking</span>.
                  </div>
                  <div className="subtitle">What does this mean?</div>
                  <div style={{marginBottom: '2rem'}}>
                    Richness is a score of how many different types of bacteria
                    are in {userInfo.dog_name}’s gut. A low richness means that
                    they do not have a good variety of bacteria that can support
                    them. They may need an urgent change in diet or lifestyle.
                  </div>
                  <div className="subtitle">Why should I care?</div>
                  <div>
                    Having a low richness score puts {userInfo.dog_name} at risk
                    of chronic diseases such as allergies and digestion issues.
                    They may not have the appropriate types of bacteria
                    necessary for breaking down nutrients and maintaining good
                    health.
                  </div>
                </>
              </>
          )}
          <BottomBlock>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {userInfo?.richness_food_recommendation?.length ? (
                <>
                  <div className="subtitle">What to feed:</div>
                  {userInfo?.richness_food_recommendation.map((elem) => (
                    <div
                      key={elem.id}
                      style={{position: 'relative'}}
                      onMouseOver={(e) => (
                        e.stopPropagation(), setIsImageToShow(elem.id)
                      )}
                      onMouseLeave={() => setIsImageToShow('')}
                    >
                      <a href={elem.link_url} target="_blank" rel="noreferrer">
                        <img
                          src={elem.image_url}
                          className={`food-image ${
                            idImageToShow === elem.id && 'hover'
                          }`}
                        />
                        {idImageToShow === elem.id && (
                          <div className="text-for-icon">{elem.name}</div>
                        )}
                      </a>
                    </div>
                  ))}
                </>
              ) : (
                ''
              )}
            </div>
            {userInfo.show_supplements && <a
              className="needaboost-btn"
              href="https://www.treattherapeutics.com/products/happy-healthy?utm_source=report&utm_medium=button&utm_campaign=report-rec-happy&utm_id=report-rec-happy"
              target="_blank"
              rel="noreferrer"
            >
              Need a boost?
            </a>}
          </BottomBlock>
        </BlockWithHeader>
      </div>
      <Blob variant={2} />
    </Container>
  );
}

export default React.memo(RichnessGraphPage) as FunctionComponent<PageProps>;
