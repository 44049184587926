export const COLORS = {
	blue: '#3969b2',
	white: '#fdfdf1',
	grey: '#c0c0b7',
	pink: '#ed3360',
	purple: '#EAA3C7',
	teal: '#72cac4',
	green: '#a2d19a',
	yellowDark: '#e5ab3c',
	yellowLight: '#f1e24c',
	orange: '#f26326',
	pinkLight: '#eee6dd',
	beige: '#f0e6d7',
	brown: '#dfb870',
	darkBrown: '#d78154',
	mainPink: '#e6abac',
	darkPurple: '#832f56',
	lightblue: '#81bacd',
	darkGreen: '#365343'
};
